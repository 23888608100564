import styled from "styled-components";

export const Wrapper = styled.section`
  /* position: relative; */
  padding: 0 4rem;
  @media (max-width: 800px) {
    padding: 0 1.5rem;
  }
`;

export const TextCon = styled.div<{ image: string; imageMobile: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 6rem 1.5rem;
  background-image: url(${(probs) => probs.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 35px;
  @media (max-width: 800px) {
    height: 450px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.41),
        rgba(0, 0, 0, 0.48)
      ),
      url(${(probs) => probs.imageMobile});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const BtnCon = styled.div`
  margin: 2rem 0;
`;

export const Btn = styled.button`
  border: 2px solid #ffffff;
  border-radius: 30px;
  padding: 15px 20px;
  width: 251px;
  height: 52px;
  background: transparent;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.8px;
  font-family: "geist-regular";

  &:hover {
    background-color: #ffffff;
    color: #10281e;
  }
  @media (max-width: 800px) {
    width: 192px;
  }
`;

export const Dots = styled.div`
  margin: 2rem 0 0 0;
`;

export const Dot = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) =>
    isActive ? "#ffffff" : "rgba(255, 255, 255, 0.5)"};
  font-size: 15px;
  cursor: pointer;
  margin-left: 10px;
`;
