import React from "react";
import {
  Container,
  Wrapper,
} from "./ExploreSection_styles";
import {
  Heading1,
  SpanText,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";

type ExploreSectionProps = {};

const ExploreSection: React.FC<ExploreSectionProps> = () => {
  return (
    <Wrapper>
      <Container>
        <Heading1
          color={`${theme.colors.white}`}
          fontSize="36px"
          fontWeight="900"
          fontFamily={'"Kumbh Sans", sans-serif'}
          letterSpacing="0.8px"
          textAlign="center"
          fs="20px"
          width="80%"
          w="100%"
          margin="0 auto"
        >
          “Explore our diverse community of{" "}
          <SpanText
            color={`${theme.colors.lightGreen}`}
            fontWeight="900"
            fontFamily={'"Kumbh Sans", sans-serif'}
            fs="20px"
          >
            African
          </SpanText>{" "}
          singles, where shared values, interests, and cultural backgrounds
          blossom into heartfelt
          <SpanText
            color={`${theme.colors.lightGreen}`}
            fontWeight="900"
            fontFamily={'"Kumbh Sans", sans-serif'}
            fs="20px"
          >
            {" "}
            connections
          </SpanText>
          ”
        </Heading1>
      </Container>
    </Wrapper>
  );
};
export default ExploreSection;
