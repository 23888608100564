// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
    font-size: 24px;
  }
  
  @media (max-width: 800px) {
    .icon {
      font-size: 20px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/presentations/faqs/index.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB;;EAEA;IACE;MACE,eAAe;IACjB;EACF","sourcesContent":[".icon {\n    font-size: 24px;\n  }\n  \n  @media (max-width: 800px) {\n    .icon {\n      font-size: 20px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
