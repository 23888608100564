export const footerLinks = [
  {
    topText: "About Us",
    links: [
      // {
      //   value: "Mission",
      //   to: "/",
      // },
      {
        value: "Careers",
        to: "/career",
      },
      // {
      //   value: "Press",
      //   to: "/",
      // },
      {
        value: "Contact Us",
        to: "/contact-us",
      },
    ],
  },
  {
    topText: "Legal",
    links: [
      {
        value: "Security",
        to: "/",
      },
      {
        value: "Privacy",
        to: "https://app.termly.io/policy-viewer/policy.html?policyUUID=c6a38ee7-fa93-4274-9087-06d9d065a1e5",
      },
      {
        value: "Terms Of Service",
        to: "https://app.termly.io/policy-viewer/policy.html?policyUUID=411621be-2d4b-4fda-bc4a-46cb1b2d767c",
      },
      {
        value: "Cookies Policy",
        to: "https://app.termly.io/policy-viewer/policy.html?policyUUID=e83d5f78-82b2-4763-9d03-da79c4658f8f",
      },
    ],
  },
  {
    topText: "Resources",
    links: [
      {
        value: "FAQ",
        to: "/faqs",
      },
      {
        value: "Get Help",
        to: "/contact-us/help-and-support",
      },
      // {
      //   value: "Dating Advice",
      //   to: "/",
      // },
      {
        value: "Safety Tips",
        to: "/",
      },
      {
        value: "Community Guidelines",
        to: "/community-guidelines",
      },
    ],
  },
];
