import React from "react";
import {
  Container,
  GridBoxes,
  GridCon,
  LinkCon,
  Wrapper,
} from "./Support_styles";
import {
  CommonText,
  Heading1,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { support } from "../../data/data";
import { IoIosArrowForward } from "react-icons/io";
import { theme } from "../../../../theme/theme";
import { Helmet } from "react-helmet-async";

const Support = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>Contact-us - Spowse</title>
        <meta
          name="description"
          content="Have questions or need support? Contact the Spowse team for assistance with your dating experience. We're here to help with any inquiries or feedback. Reach out to us for quick and friendly support, and let us guide you on your journey to finding meaningful connections."
        />

        <meta charSet="utf-8" />
        <link rel="canonical" href={`/contact-us/help-and-support`} />
      </Helmet>
      <Container>
        <Heading1
          textAlign="center"
          fontSize="50px"
          lineHeight="63px"
          letterSpacing="0.8px"
          color={`${theme.colors.primary}`}
          fs="28px"
          lh="35px"
          margin="1rem 0"
        >
          Welcome To Your Spowse Support Hub
        </Heading1>
        <CommonText
          textAlign="center"
          margin="0 auto"
          letterSpacing="0.2px"
          fontSize="16px"
          fontWeight="400"
          color="#282D2B"
          lineHeight="20px"
          width="60%"
          w="100%"
        >
          {" "}
          Have a question, need help, clarity or you have news to share? Reach
          out! We are here to support you on your Spowse journey
        </CommonText>
        <GridCon>
          {support.map((item, index) => (
            <GridBoxes key={index}>
              <SpowseImage src={item?.icon} alt="img" width="45px" />
              <Heading1
                textAlign="center"
                fontSize="24px"
                lineHeight="30px"
                letterSpacing="0.8px"
                color={`${theme.colors.primary}`}
                fs="20px"
                lh="25px"
                margin="1rem 0"
              >
                {item?.title}
              </Heading1>
              <CommonText
                textAlign="center"
                letterSpacing="0.8px"
                fontSize="16px"
                fontWeight="400"
                color="#282D2B"
                lineHeight="20px"
              >
                {item?.desc}
              </CommonText>
              <a href={item?.url} style={{ textDecoration: "none" }}>
                <LinkCon>
                  <CommonText
                    textAlign="center"
                    letterSpacing="0.8px"
                    fontSize="16px"
                    fontWeight="400"
                    color={`${theme.colors.secondary}`}
                    lineHeight="20px"
                  >
                    {item?.linkText}
                  </CommonText>
                  <IoIosArrowForward />
                </LinkCon>
              </a>
            </GridBoxes>
          ))}
        </GridCon>
      </Container>
    </Wrapper>
  );
};

export default Support;
