import React from "react";
import { Wrapper, Container, Right, Left } from "./Hero_styles";
import {
  CommonText,
  Heading1,
  Heading3,
  SpanText,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../../core/utils/app_images";

const Hero = () => {
  return (
    <Wrapper>
      <Container>
        <Left>
          <Heading3
            color={`${theme.colors.secondary}`}
            fontWeight="900"
            fontSize="16px"
            lineHeight="20px"
            letterSpacing="0.8px"
            fs=" 12px"
            lh="15px"
          >
            SECURITY ON SPOWSE
          </Heading3>
          <Heading1
            color={`${theme.colors.primary}`}
            fontWeight="700"
            fontSize="60px"
            lineHeight="75px"
            letterSpacing="0.8px"
            fs="30px"
            lh="38px"
            margin="0.5rem 0"
            // width="80%"
            // w="100%"
          >
            Your{" "}
            <SpanText
              color={`${theme.colors.secondary}`}
              fontSize="inherit"
              fontWeight="700"
              letterSpacing="inherit"
              lineHeight="inherit"
              fs="inherit"
              lh="inherit"
              fontFamily="inherit"
            >
              Safety
            </SpanText>{" "}
            Always Comes{" "}
            <SpanText
              color={`${theme.colors.secondary}`}
              fontSize="inherit"
              fontWeight="700"
              letterSpacing="inherit"
              lineHeight="inherit"
              fs="inherit"
              lh="inherit"
              fontFamily="inherit"
            >
              First
            </SpanText>
          </Heading1>
          <CommonText
            width="550px"
            wid="100%"
            color={`${theme.colors.primary}`}
            fontWeight="400"
            fontSize="16px"
            lineHeight="20px"
            letterSpacing="0.8px"
          >
            At Spowse, we prioritize your security, creating a trusted platform
            for African singles that goes above and beyond the basics
          </CommonText>
        </Left>
        <Right>
          <SpowseImage
            src={AppImages.safetyHero}
            width="100%"
            height="100%"
            alt="img"
          />
        </Right>
      </Container>
    </Wrapper>
  );
};

export default Hero;
