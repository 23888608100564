import React, { CSSProperties, useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { Container } from "./styles";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
};

const Loader = () => {
  let [loading, setLoading] = useState(true);

  return (
    <Container>
      <BounceLoader cssOverride={override} loading={loading} color="#004021" />
    </Container>
  );
};

export default Loader;
