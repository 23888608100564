import React from "react";
import { Container, Wrapper } from "./Hero_styles";
import {
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";

const Hero = () => {
  return (
    <Wrapper>
      <Container>
        <Heading3
          color="#F2F2F2"
          fontWeight="900"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          fs=" 12px"
          lh="15px"
          textAlign="center"
        >
          RESOURCES
        </Heading3>
        <Heading1
          color={`${theme.colors.white}`}
          fontWeight="900"
          fontSize="60px"
          lineHeight="75px"
          letterSpacing="0.8px"
          fs="30px"
          lh="38px"
          margin="0.5rem 0"
          textAlign="center"
        >
          Community Guidelines
        </Heading1>
      </Container>
    </Wrapper>
  );
};

export default Hero;
