import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const WhySpowseWrapper = styled.section`
  width: 100%;
  background: ${theme.colors.bgPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WhySpowseContent = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6rem;
  padding: 5rem;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    padding:5rem 1.5rem;
    gap: 2rem;
  }
`;

export const WhySpowseLeftSection = styled.div`
  flex: 1;
  display: flex;
  align-items: start;
  justify-content: start;
`;

export const WhySpowseRightSection = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  /* gap: 1rem; */

  @media (max-width: 800px) {
    gap: 0px;


  }
`;
