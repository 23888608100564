import styled from "styled-components";

export const Wrapper = styled.section`
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.79),
      rgba(0, 0, 0, 0.93)
    ),
    url("/assets/images/guideline.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  @media (max-width: 800px) {
    height: 400px;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.79),
      rgba(0, 0, 0, 0.69)
    ),
    url("/assets/images/guideline.svg");
  }
`;

export const Container = styled.div`
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    background-color: rgba(234, 240, 238, 0.05);
    padding: 1.5rem 0;
`;
