import ApiService from '../api';
import { ApiUrl } from '../api_url';

interface SuccessStoryData {
  firstName: string;
  lastName: string;
  email: string;
  partnerDescription: string;
  story: string;
}


class SuccessStoryService {
  private apiService: ApiService;   

  constructor() {
    this.apiService = new ApiService();
  }

  async submitSuccessStoryForm(data: SuccessStoryData): Promise<any> {
    const response = await this.apiService.postData<SuccessStoryData, any>(ApiUrl.SUCCESS_STORIES, data);
    
    return {
      ...response,
      message: response.message || 'No message provided',
    };
  }
}

export default new SuccessStoryService();
