import styled from "styled-components";

export const Wrapper = styled.section``;

export const Container = styled.div`
  width: 100%;
  max-width: 1500px;

  margin: 0 auto;
`;

export const Categories = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 4rem 4rem 0 4rem;
  overflow-x: auto; /* Ensure scrolling still works */
  
  /* Hide scrollbar for webkit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Adjust for smaller screens */
  @media (max-width: 1100px) {
    gap: 20px;
    width: 100%;
  }

  @media (max-width: 800px) {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }

  /* Optional: Smooth scrolling */
  scroll-behavior: smooth;
`;


export const EactCat = styled.div<{ active: boolean }>`
  background-color: ${({ active }) => (active ? "#099838" : "#ffffff")};
  color: ${({ active }) => (active ? "#ffffff" : "#282D2B")};
  border: 1px solid
    ${({ active }) =>
      active ? "rgba(255, 255, 255, 0.1)" : "rgba(234, 236, 240, 1)"};
  padding: 12px 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.8px;
  white-space: nowrap;
  width: 170px ;
  height: 43px;
  &:first-child {
    width: 122px !important;
  }

  &:hover {
    background-color: #099838;
    color: #ffffff;
  }
`;

export const GridCon = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 50px;
  row-gap: 100px;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  padding: 4rem 4rem 0 4rem;
  @media (max-width: 1160px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 50px;
    padding: 2rem 1.5rem 0 1.5rem;
  }
`;

export const GridItems = styled.div``;

export const ImageCon = styled.div`
  width: 100%;
`;
