import styled from "styled-components";

export const Wrapper = styled.section`
    background-color: #EFF7F4;
    @media (max-width: 800px) {
      /* margin: 1rem 0; */
  }
`

export const Container = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    padding: 6rem 4rem;
    @media (max-width: 800px) {
    padding: 4rem 1.5rem;
  }
`