export const partnerLogos = [
    {
        image :"/assets/images/logo1.png"
    }, {
        image :"/assets/images/logo2.png"
    }, {
        image :"/assets/images/logo3.png"
    }, {
        image :"/assets/images/logo4.png"
    }, {
        image :"/assets/images/logo5.png"
    }
]