export const postss = [
  {
    id: 1,
    image: "/assets/images/grid1.svg",
    category: "DATING TIPS",
    title: "Things to do for singles looking for successful relationships",
    date: "26 May 2023",
  },
  {
    id: 2,
    image: "/assets/images/grid2.svg",
    category: "HOW-TOS",
    title: "Get rid of your social anxiety with these few tips",
    date: "26 May 2023",
  },
  {
    id: 3,
    image: "/assets/images/grid3.svg",
    category: "DATING TRENDS",
    title: "How to tell a person’s personality from social media",
    date: "26 May 2023",
  },
  {
    id: 4,
    image: "/assets/images/grid4.svg",
    category: "SPOWSE UPDATES",
    title: "All there is to know about Spowse and her features",
    date: "26 May 2023",
  },
  {
    id: 5,
    image: "/assets/images/grid5.svg",
    category: "SUCCESS STORIES",
    title: "An all you need to know guide about online dating for Africans",
    date: "26 May 2023",
  },
  {
    id: 6,
    image: "/assets/images/grid6.svg",
    category: "SUCCESS STORIES",
    title: "Stand out in the dating world with these few tips",
    date: "26 May 2023",
  },
  {
    id: 7,
    image: "/assets/images/grid7.svg",
    category: "DATING TRENDS",
    title: "Top ten best places to go on a first date",
    date: "26 May 2023",
  },
  {
    id: 8,
    image: "/assets/images/grid8.svg",
    category: "HOW-TOS",
    title: "Stand out in the dating world with these few tips",
    date: "26 May 2023",
  },
  {
    id: 9,
    image: "/assets/images/grid9.svg",
    category: "SPOWSE UPDATE",
    title: "All there is to know about Spowse and her features",
    date: "26 May 2023",
  },
];

export const categories = [
  {
    cat: "All",
  },
  {
    cat: "Dating Tips",
  },
  {
    cat: "How Tos",
  },
  {
    cat: "Quizzes",
  },
  {
    cat: "Guest Features",
  },
  {
    cat: "Spowse Updates",
  },
];

export const blogs = [
  {
    id: "hhyetfgfghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    title: "A Guide to Successfully Dating Online: Women’s Edition",
    read: "4 min read",
    image: "/assets/images/trybe11.png",
    category: "DATING TIPS",
    image2: "/assets/images/trybe1_sub.png",
    contents: [
      "Let’s face it, finding love has become harder, especially in this time and age. Naturally, many people expect dating apps to make it easier for them to find love.",
      "Unfortunately, dating apps can’t help you fall in love with someone or help anyone fall in love with you. Dating apps provide the means to meet people and form bonds, but that’s about it. It’s up to you to determine how successful your online dating experience will be.",
      "You might wonder what you need to do to achieve this success as a woman. To be honest, it’s not rocket science. Many people just don’t know what to do but you’ll learn since you’re here",
    ],
    subContents: [
      {
        image: null,
        topic: "How to Succeed at Online Dating as a Woman",
        supTopic: "",
        contents: [
          "Women face unique challenges while dating online which is why we’ve written this article to act as a guide for you. Here are a few ways to succeed at online dating:",
        ],
      },
      {
        image: null,
        topic: "Pick a dating app that meets your needs",
        supTopic: "",
        contents: [
          "There are many dating apps and each one has a unique focus and target audience it caters to. Your needs can be based on your dating interests, sexual orientation or priorities.",
          "For example, Tinder is known for hookups, Bumble is a women-oriented app, Grindr is for gay men, and Spowse helps you meet others looking to build serious relationships.",
          "Do you get the picture now? The bottom line is, you need to find a dating app that caters to your needs",
        ],
      },
      {
        image: "/assets/images/sub_c1.png",
        topic: "Set up a catchy dating profile ",
        supTopic: "",
        contents: [
          "After creating your account on a dating site, the next step is to set up a dating profile that shows who you truly are. Think of the things that truly make you unique and try to showcase your hobbies and interests.",
          "Your dating profile is what attracts other users to a dating platform. If someone finds your dating profile intriguing enough, they might decide to swipe on your profile. Getting a Swipe means you’re more than one step forward to meeting your person.",
        ],
      },
      {
        image: null,
        topic: "Learn to make the first move",
        supTopic: "",
        contents: [
          "Take control of the power dynamics. By doing this, you are taking control of your relationship, you’re not just sitting around and waiting for a man to swipe on your profile",
          "Many women shy away from reaching out to potential love interests first but it’s really not a big deal. So what if he says no or unmatches? If he does, just move along and go on with the day’s business.",
          "Men are also shy and hesitant to hit up women online, so will you let Prince Charming go just because you want to abide by the traditional rules of dating?",
          "When you make the first move, the ball is in your court so you can decide how fast or slow you want things to progress with your new match.",
        ],
      },
      {
        image: null,
        topic: "Be conversational",
        supTopic: "",
        contents: [
          "No one likes a boring person and you know what they say about first impressions lasting longer. You have to put your best foot forward always. Don’t text like you’re distracted or you’d rather be somewhere else (even if it’s true).",
          "You should respond to your texts with depth and intentionality because, beyond pretty faces, men desire women who are intelligent and can hold their own in conversations.",
        ],
      },
      {
        image: null,
        topic: "Set realistic standards",
        supTopic: "",
        contents: [
          "Having realistic standards will help you date with a purpose. You’re no longer looking for personalities from your fairytale movies or your favourite Wattpad novels because they are unrealistic as it gets. Rather, you’re looking for someone who possesses qualities that complement yours which will help your relationship stand the test of time.",
          "It’s also important to note that whatever standards you are setting for a potential partner should be a reflection of yourself or at least similar in most ways. Else, your to-be partner might not be very happy with you which could lead to your relationship failing.",
        ],
      },
      {
        image: null,
        topic: "Wrapping Up",
        supTopic: "",
        contents: [
          "There’s no foolproof way to date online but we’re very sure that if you are mindful of the things we’ve discussed on this list, you won’t have to worry about the success of your dating experience.",
          "Another way to ensure a superb online dating experience is by using an app that truly cares about you and takes your needs into consideration and Spowse is that app.",
          "Sign up on our waitlist to get notified when we launch.",
        ],
      },
    ],
  },
  //   blog 2
  {
    id: "hhyetfgfuuyerccsghkjklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    title: "9 Types of Lovers: What Type of Lover Are You?",
    read: "5 min read",
    category: "DATING TRENDS",
    image: "/assets/images/trybe22.png",
    image2: "/assets/images/dog_lauging.png",
    contents: [
      "You’ve probably been dating since your teenage years but have you ever pumped the brakes on your dating life to really get to know what type of lover you might be?",
      "We recently posted a romantic personality quiz based on Game of Thrones (GOT) characters for fun. Many people seemed happy to share their results but a few people rejected their results, we think they should look inward.",
      "Just joking.",
      "Now let’s get serious. ",
      "As humans, it’s easy to blame others for our dating fails. Furthermore, research has shown that love styles are associated with individual differences in relational and communicative behaviour across the lifespan of romantic relationships. Understanding what type of lover you are can help you learn and unlearn certain behavioral traits to improve your love life.",
      "In this article, we’ll give an in-depth insight into different romantic personalities. All you need to do is read and as we said earlier, look inward. Do you think you’re a good partner? Would your partner look at you and bless the day you made your grand entrance on earth?",
      "Read on to find out! ",
    ],
    subContents: [
      {
        image: "/assets/images/so_lets_begin.png",
        topic: "The Giver",
        supTopic: "9 Types of Lovers and Their Approach to Relationships",
        contents: [
          "The giver is usually a selfless person. However, giving excessively can also stem from insecurity and trying to overcompensate because they think they aren’t worthy of their lovers. Being a giver shouldn’t be considered from the material aspect alone. We can give emotionally, financially and even through our acts of service.",
          "The giver tends to keep giving even at their own detriment. They would go to the ends of the Earth to make sure their lover doesn’t lack anything that’s within their capability to provide.",
          "While this can be an admirable trait, it’s important to know when to stop giving and pay attention to your own needs. Your body, mind and soul also need to be taken care of but when all that attention is directed towards someone else, it quickly becomes a problem and you lose yourself trying to please another.",
        ],
      },
      {
        image: null,
        topic: "The Taker",
        supTopic: "",
        contents: [
          "These set of people are selfish lovers. They constantly demand love, attention and material things from their partners but never return the energy they’re getting. Takers always want things to work in their own way and will never accept their responsibilities in a relationship. Rather, they blame it on their partners and refuse to be accountable.",
          "Takers believe that their partner’s world should revolve around their existence and that they don’t need to do anything to make their partner(s) happy. When takers don’t get what they want, they spiral and lose their composure.",
        ],
      },
      {
        image: null,
        topic: "The Romantic",
        supTopic: "",
        contents: [
          "For the romantic, love is a fairytale. If you want the Romeo and Juliet type of love filled with burning and intense passion, a romantic is your best bet. A romantic takes great care to make their partner feel loved and appreciated.",
          "They’re often living in a paradise that they’ve built in their heads where everything is going on smoothly with their lover. Despite how carefree they might appear, romantics might not fall in love easily but when they do, they fall hard.",
          "However, Romantics need to understand that relationships won’t always be smooth sail. As much as they desire for it to be all rainbow and popsicles, there will be ups and downs and you’ll need to ride the wave out with your partner.",
        ],
      },
      {
        image: null,
        topic: "The Mania",
        supTopic: "",
        contents: [
          "Much like babies, the mania is often heavily dependent on their partner, especially for their emotional needs. This can border on obsession if it’s not well-controlled. This type of lover tends to need constant reassurance in a relationship and this can wear their partner(s) out in the long run.",
          "People like this will likely have highs (peaks of joy) and downtimes when they experience bouts of sorrow. Their partners are usually their sole source of happiness and in the absence of a partner, they are very likely to feel lost. In addition, the Mania can be quite possessive and jealousy can be an issue for these individuals.",
        ],
      },
      {
        image: null,
        topic: "The Parent",
        supTopic: "",
        contents: [
          "This type of lover often wants to nurture their partners. They give the type of warmth, love and care that parents often extend to their children. Nurturing is simply their way of showing their love for their partner and they derive utmost pleasure in doing so. While many people tend to think that only women can be nurturers, men can also be nurturers.",
          "Despite how swoon-worthy this type of lover might be, they can quickly become a pain especially when they don’t know when to stop being ‘parents’. Some of these type of lovers tend to be controlling and try to treat their lovers like their child thereby, restricting their freedom and independence to do certain things or take decisions.",
          "If you fall under this category, you also need to guard your heart jealously as many people love the care and attention they get from you and they don’t really plan to give you the same energy. It’s highly recommended that you have a partner that complements your nurturing and love style.",
        ],
      },
      {
        image: null,
        topic: "The Jolly rider ",
        supTopic: "",
        contents: [
          "The loyal lover is a ride-or-die lover who devotes themselves to loving their partner and doing right by them. They will often go out of their way to please their lover and ensure that they are the centre of attention.",
          "The loyal lover sticks with their partner through the rough patches of their relationship. They have moral principles, and they don’t go looking for opportunities to cheat on their partner. Even if they get the opportunity to do so, they’re content with their partners and don’t stray at the first opportunity that comes their way.",
        ],
      },
      {
        image: "/assets/images/trybe22.png",
        topic: "The Pragmatist",
        supTopic: "",
        contents: [
          "This type approaches love from a logical perspective. Emotions hardly come into play when they’re handling matters of the heart. Logic is used to determine compatibility and future prospects. This doesn’t mean that these individuals are emotionless or soul-less, they simply like to be practical about their expectations from a potential partner or in a relationship.",
          "They rate the pool of potential partners available to them based on the values and traits that are most important to them in a relationship. These needs can be financial, emotional or social depending on the individual.",
        ],
      },
      {
        image: "/assets/images/conclusion.png",
        topic: "Conclusion",
        supTopic: "",
        contents: [
          "Now that you’re done reading and thinking, you might realize that you fit into more than one category on this list. That’s not a problem and if negative, don’t stress about it. You only need to dial down on those traits and ensure you work towards being a better person.",
          "The major aim of this article is to enlighten you and help you learn a little more about your love life. If you think you’ve been exhibiting unpleasant traits, it’s time to work on those traits. Help your partner love you better and find love more easily.",
        ],
      },
    ],
  },

  //   blog 3
  {
    id: "hhyetfgfuuyerccsghkjkjuygfrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    title: "“Prioridating” and How It Can Help You Find Long-Term Love",
    read: "6 min read",
    category: "DATING TIPS",
    image: "/assets/images/trybe33.png",
    image2: "/assets/images/trybe3_sub.png",
    contents: [
      "Have you ever been in a relationship where you thought you were in for the long run but you get to know them and discover traits that you can’t put up with? Have you tried many dating techniques and they’ve all proved abortive? Then you need to try “Prioridating”.",
      "“Prioridating” is a lowkey dating technique that’s quite promising. This technique which was created by Laurel House is guaranteed to land you a long-lasting relationship.",
    ],
    subContents: [
      {
        image: null,
        topic: "What is Prioridating?",
        supTopic: "",
        contents: [
          "According to Laurel House, Prioridating is “dating on purpose, the purpose being to find someone who fulfils the one most important thing you need in a relationship”.",
          "Many times, we have all these superficial things we desire in our partner, we want them to have perfect legs, toned bodies and even a jawline to die for. It’s not wrong to want these things but they shouldn’t take the place of values that can help sustain your relationship.",
          "Prioridating is realizing that you want more from a potential partner outside their physical attributes. To prioridate is to go after values or attributes that are non-negotiable to you in a relationship and only set out to date people who have these values.",
          "Instead of looking out for someone that ticks off all the vanities you want in a man, why not look out for someone who offers the things you cherish most in a relationship?",
        ],
      },
      {
        image: null,
        topic: "How to Start Prioridating",
        supTopic: "",
        contents: [
          "You can’t prioridate without knowing what you desire most in your relationship. To know these needs, you have to consciously decide which values and character traits you want in a partner. Think about all the times you’ve experienced pure joy and happiness, what activities make you feel in touch with yourself, and when have you felt the safest and most at peace?",
          "What’s that thing that will give you satisfaction in a partner? These needs differ from one person to another which is why you shouldn’t just choose a value or need because everyone is after that. For some people, these needs can be physical, emotional or financial.",
          "Your values have to reflect in your conversations, thought processes, actions and attitude. Remember you only attract traits you exude. Laurel House says “Align with that value in all ways”.",
          "You should reflect those traits you desire in a partner in how you talk, what you say, the types of dating apps you use and even your future expectations.",
        ],
      },
      {
        image: null,
        topic: "How Prioridating Can Help You Find Long-Term Love",
        supTopic: "",
        contents: [
          "Bear in mind that you can date for a long time and still date for all the wrong reasons. However, when you prioridate, you are dating with intentions and working towards a goal with your intended partner.",
          "Now that we know what prioridating is and how to go about it, let’s take a look at how it can help you secure the long-term relationship you desire.",
        ],
      },
      {
        image: "/assets/images/image 6.png",
        topic: "Perks of Prioridating",
        supTopic: "",
        contents: [
          "When you start putting your priorities first you’ll notice a drastic change in your relationship and the connections you make in the process of building your relationship(s). Here are the advantages of prioridating:",
        ],
      },
      {
        image: null,
        topic: "It helps you make genuine connections",
        supTopic: "",
        contents: [
          "According to Jelena Kecmanovic for the Washington Post, it’s most effective to be your honest self when dating online. The process of realising your core values brings a lot of self-awareness which helps you to correctly identify and clearly communicate who you are.",
          "By having a clear understanding of your needs in a relationship, prioridating helps you uncover truths about yourself and effectively align your emotional necessities. If your partner is also open about communicating their core values, it could help you forge meaningful and long-lasting connections over time.",
        ],
      },
      {
        image: null,
        topic: "It improves communication",
        supTopic: "",
        contents: [
          "The gradual process of self-realization, once you start putting your priorities first, helps you know what you want, and how you wish to be treated and effectively communicate that to a potential love interest. This way, you are clear about your needs and there’s no confusion about what you desire in a partner.",
        ],
      },
      {
        image: null,
        topic: "It encourages intentionality",
        supTopic: "",
        contents: [
          "Meeting a partner whose priorities align with yours or who is ready to give you what you desire takes a lot of intentionality on their part. This means that while they are meeting your needs and making you happy, you are also doing the same for them. After all, prioridating is about making sure your deepest desires/needs in a partner are met.",
        ],
      },
      {
        image: null,
        topic: "It shows that you are self-aware",
        supTopic: "",
        contents: [
          "As you learn more about yourself, you are able to show those you come across who you really are and all the unique traits that make up your personality. This tells whoever you are communicating with that you aren’t just blabbing and chasing fantasies. Rather, you come off as someone who has a sense of security knows what they want and will go after it.",
        ],
      },
      {
        image: null,
        topic: "Prioridating is not Settling",
        supTopic: "",
        contents: [
          "At this point, you are probably wondering if you should give this a try but you also don’t want to discard all the must-haves you have on your bucket list.",
          "You need to know that prioridating is very different from settling. When prioridating, you are simply making sure that you are only devoting time and energy towards things that really matter. Once you identify your most important needs, you can align all other things with that.",
        ],
      },
      {
        image: "/assets/images/summary.png",
        topic: "Summary",
        supTopic: "",
        contents: [
          "Prioridating helps you address your deeply rooted needs and also create meaningful and intentional connections or relationships instead of just dating anyone you’re attracted to.",
          "Although you shouldn’t just date people because they fulfil one need, they should be willing to constantly evolve and be open to learning. The willingness to learn and evolve with your partner is one that is paramount for the success of any relationship.",
          "Bear in mind that your needs will change as you continue to grow and go through different phases of life. You won’t be the same person you were when you met your partner and that’s totally fine. Having a partner who understands this will help your relationship sail smoothly.",
        ],
      },
    ],
  },

  //   blog 4
  {
    id: "hhyetfgfuuyerccsghkfrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    title: "5 tips to step up your dating game as a man",
    read: "5 min read",
    category: "DATING TIPS",
    image: "/assets/images/trybe44.png",
    image2: "/assets/images/trybe4_sub.png",
    contents: [
      "It’s one thing to impress a woman and an entirely different thing to make her fall in love with you for life.",
      "Oftentimes, you might feel lost, anxious and confused about what to do to impress a woman and make her stay interested in you",
      "There’s also the complexity of the dating game in this age. Things have changed a lot from what they used to be. There are all these new dating rules and etiquette you have to abide by to pull the want.",
      "It’s no surprise that it is significantly harder to get a partner but we’re here to help you.",
      "In this article, we discuss tips to step up your dating game and how to get the woman of your dreams.",
    ],
    subContents: [
      {
        image: null,
        topic: "How to up your dating game and get the girl of your dreams",
        supTopic: "",
        contents: [
          "Don’t let anyone sell you lies that women are difficult to interact with or build a relationship with.",
          "Here are tips that can improve your dating experience as a man:",
        ],
      },
      {
        image: null,
        topic: "Make the Move",
        supTopic: "",
        contents: [
          "Like we mentioned earlier, women are human too so there’s absolutely no need for you to be scared or overly anxious.",
          "In many instances, your first conversation might be awkward but that shouldn’t deter you from making your intentions known to someone you like.",
          "Think of it like this. Would you rather express your feelings and know where you stand with her or you’d rather suffer in silence?",
          "The last option might offer you some comfort but the comfort will be short-lived when you see her with some other man who was bold enough to scale the hurdle of letting her know his feelings.",
          "If the thought of that hurt you or made you feel some type of way, it’s your sign to tell that woman how you feel.",
        ],
      },
      {
        image: "/assets/images/aguy.png",
        topic: "Be Clear and Direct",
        supTopic: "",
        contents: [
          "So you shot your shot, now it’s time to be direct about what you really want. This doesn’t mean you need to be sure whether you want to marry them or not. All you need to know and be clear about is if you’d like to move things to the next level.",
          "Ignore these rules that dictate when to reach out to a woman you like after the first date, it’s never too soon. Who makes those rules anyway?",
          "It takes a mix of vulnerability and confidence to tell a woman how you feel and women absolutely love men who know what they want and confidently go after it.",
          "So do just that, just make sure you don’t come off as desperate or greedy for her attention (even if you are).",
          "Simply let her know you enjoy your time with her and you’d love to see more of her soon.",
        ],
      },
      {
        image: null,
        topic: "Be Yourself",
        supTopic: "",
        contents: [
          "Relax and be yourself.",
          "This might sound cliché but it holds true, even for women. No one will take you seriously if you’re not confident about what you’re bringing to the table.",
          "If you aren’t proud of yourself, Why should they care enough to pay attention to you?",
          "You get the point?",
          "When walking up to a woman, make sure you exude confidence. If you’re shy or anxious, practicing in front of a mirror beforehand might help you. You can also prep with conversation starters that can help you engage her in deep and meaningful discussions.",
        ],
      },
      {
        image: null,
        topic: "Don’t Brag ",
        supTopic: "",
        contents: [
          "Avoid bragging about who you are or what you’re worth.",
          "You risk giving loser energy when all you talk about is how much you earn, fast cars or the mansion you live in.",
          "If you aren’t proud of yourself, Why should they care enough to pay attention to you?",
          "If she’s the one for you, she will love you for who you are and not what you have.",
        ],
      },
      {
        image: "/assets/images/image 2.png",
        topic: "Be Intentional",
        supTopic: "",
        contents: [
          "Women love a man who can take initiative and put his heart and brain into whatever he is doing. No woman likes a passive man or a bystander.",
          "Learn her interests, observe her even down to the tiny details, and get to know what she needs or desires. In essence, learn how you can support her in any aspect possible.",
          "This doesn’t mean you need to start bankrolling her, in fact, it’s not very advisable to throw money around when you’re trying to get a woman’s attention. Otherwise, you’ll end up pulling gold-diggers who only want your money.",
          "Understand her dreams, priorities and be supportive. Your support can come in the form of acts of service, emotional support and kindness are worth more than material gifts.",
        ],
      },
      {
        image: null,
        topic: "Use Dating Apps ",
        supTopic: "",
        contents: [
          "Ever since dating apps became a thing, the dating game has never been the same. Many people have found love through online dating and if you’re lucky enough, you can too.",
          "If you’re a homebody, you should absolutely try dating apps as they take away the hassle of leaving the comfort of your home to meet people.",
          "That’s not all.",
          "You also get to skip all the formalities that you’d have to go through if you were meeting a potential love interest offline. All you’d need to do is swipe through the options which must have been tailored according to your interests.",
        ],
      },
    ],
  },

  //   blog 5
  {
    id: "hhyetfgfuuyerccsghxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    title: "Understanding consent in relationships",
    read: "4 min read",
    category: "DATING TIPS",

    image: "/assets/images/trybe55.png",
    image2: "/assets/images/trybe5_sub.png",
    contents: [
      "Being in a casual relationship with someone comes with feeling a range of emotions at different times. These emotions can be tricky to handle especially when it comes to intimacy, even more so sex.",
      "Intimacy occurs when two or more people become physically, sexually and/or emotionally close. Knowing if the person you’re with wants to get intimate with you or not can be quite the hassle but you can’t skip this step, you need to them to give you their consent to the level of intimacy you’re about to engage in.",
    ],
    subContents: [
      {
        image: null,
        topic: "Consent in intimate relationships",
        supTopic: "",
        contents: [
          "Consent is an agreement to engage in an activity usually before the activity commences. The National Domestic Violence Hotline defines consent as ‘an ongoing mutual agreement between partners about what they do or don’t want to experience’.",
          "Many people get the concept of consent wrong, believing that consent is simply getting a yes or no from their partner(s) but it’s really not that simple.",
          "Your partner’s consent to engage in a particular sexual act doesn’t automatically mean they are interested in all other activities. They need to be aware of what every activity involves and its possible outcomes. You both need to shoulder the responsibility of making sure that you’re both safe and comfortable during the act.",
        ],
      },
      {
        image: null,
        topic: "How to Start Prioridating",
        supTopic: "",
        contents: [
          "Obtaining consent isn’t only going to do you good and keep you safe from allegations that can damage your personal image, it also subtly reassures your partner that you care about their feelings and safety.",
          "It’s important that the person you’re with is happy and feels at ease about whatever activity you’re about to engage in. Engaging in non-consensual sexual activity is against the law and will have you facing legal consequences. Get familiar with the laws regarding consent in your state/country and abide by them.",
        ],
      },
      {
        image: "/assets/images/no_mean_no.png",
        topic: "Negotiating consent",
        supTopic: "",
        contents: [
          "How do you get consent?",
          "How do you know your partner is happy with what you’re doing?",
          "The only way to know if someone has given consent is if they tell you. ‘Maybe’ isn’t an answer, a nod isn’t an answer. It would be best if you didn’t have to coerce them to get consent. Sexual coercion refers to the act of being pressured or tricked to engage in a sexual activity. Any consent gotten after begging for sex is in fact no consent at all.",
          "You get consent by simply asking to know what your partner thinks or feels before any attempt. Understandably, you might be confused about what questions to ask and they might seem a bit strange at first, here are a few questions to help you ask clearer answers:",
          "Questions to ask to seek consent:",
          "‘What would you like us to do?’",
          "‘Are you happy with this?",
          "Is it okay if we go further?’",
        ],
      },
      {
        image: null,
        topic: "Conclusion",
        supTopic: "",
        contents: [
          "People communicate in many different ways that could be verbal or non-verbal.",
          "A push from your partner or visible signs of discomfort could also be interpreted as a sign that they do not wish to engage in any intimate/sexual activity anymore. It’s important to learn to take cues and interpret forms of granting consent appropriately.",
        ],
      },
    ],
  },

  //   blog 6
  {
    id: "hhyetfgfuuyerccsghxggfdterryzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    title: "5 Tips on Finding a Boyfriend or Girlfriend",
    read: "4 min read",
    category: "DATING TIPS",
    image: "/assets/images/trybe66.png",
    image2: "/assets/images/trybe6_sub.png",
    contents: [
      "Finding a partner has never been easy and it will likely not get easier anytime soon.",
      "As humans, we yearn for affection and companionship in another human. Life gets lonely sometimes and it’s not the type of loneliness that hanging out with your friends might cure",
      "This is normal.",
      "We all seek ways to get rid of loneliness and find our person.",
      "However, finding ‘the one’ isn’t a walk in the park. It has become increasingly difficult to find love nowadays (and even more difficult for introverts). It takes a lot of effort and dedication to get into a relationship and make it blossom into a beautiful partnership.",
      "Although there are different reasons why dating has become more difficult, nothing should stop you from finding the love you desire.",
      "So how do you find a partner?",
      "In this article, we will share a few tips on how you can find a partner and where you are most likely to find potential partners.",
    ],
    subContents: [
      {
        image: null,
        topic: "How to find the right person",
        supTopic: "",
        contents: [
          "Not many people know how to go about finding their love interest. Knowing how to get a boyfriend or girlfriend might seem like an impossible task in this modern age. However, you will find that it’s easier than you think.",
          "Here are a few ways to find a partner:",
        ],
      },
      {
        image: null,
        topic: "Go out",
        supTopic: "",
        contents: [
          "Cinderella didn’t find her prince charming by staying indoors neither will you find the love of your life in your house. The more you go out, the higher your chances of finding a love interest.",
          "Accept invites to hangouts, hit the gym, and go to the coffee shop or the mall. This way, you can meet more people and make more friends while having fun.",
          "Bear in mind that going out won’t automatically attract people to you, it‘s only a step in the right direction.",
          "You have to take it a step further by being approachable. Having a positive body language is the best way to achieve this. Put a smile on your face, be polite and friendly when people try to interact with you",
          "Try not to appear desperate while at it though, people know when you’re trying too hard and you might repel them.",
        ],
      },
      {
        image: null,
        topic: "Use dating apps",
        supTopic: "",
        contents: [
          "Dating apps are one of the easiest ways to find a partner in these modern times. Finding love through dating apps can be overwhelming because of the multitude of dating apps that abound.",
          "They all promise different things and it’s difficult to know what to expect. Whether you’re looking to head down the aisle or for a casual fling, there’s a dating app that caters to your preferences. For instance, an app like Spowse, connects you with other singles who are looking for a long-term relationship.",
        ],
      },
      {
        image: "/assets/images/find.png",
        topic: "Attend events",
        supTopic: "",
        contents: [
          "We’ve discussed going out with friends and hanging out but many people underestimate how easy it is to find a romantic partner at events like birthday parties, wedding ceremonies and other social engagements.",
          "It might prove daunting at first especially but it gets easier. It gets even better when you are with your friends who can help you ease into such events. You could also ask your friends to introduce you to anyone you might find attractive enough, taking the first step isn’t so bad.",
        ],
      },
      {
        image: "/assets/images/dresswell.png",
        topic: "Dress well",
        supTopic: "",
        contents: [
          "It might seem strange to you but people relate with you based on your appearance. You need to dress for the occasion and try not to appear like you are tired of life.",
          "In addition, if you’re meeting someone for the first time, you have to look good and presentable. First impressions they say lasts longer and the last thing you want to do is to give a potential lover a red flag.",
          "Many meaningful relationships have sprung from friendships or mere acquaintanceships. To find a lover, you must be proactive in your search by making consistent efforts to be social and try to build friendships with people.",
          "Breaking out of your routine lifestyle and putting yourself out there in different ways can help to increase the odds of finding a love interest for you. When you finally take all these actionable steps into cognisance, it brings you several steps closer to finding love.",
        ],
      },
    ],
  },
  {
    id: "abcdqfreerryzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    title: "5 Reasons Why You Should Consider Online Dating",
    image: "/assets/images/blog_bg_image.png",
    image2: "",
    contents: [
      "Online dating has gained more popularity over the years but not many people have fully embraced the idea for several reasons. While some people think it’s a desperate attempt, others simply dread the idea of meeting and getting romantically involved with strangers.",
      "However, online dating can be quite thrilling. You don’t need to live by the old playbook of romance, you can control who you meet and how you meet them when you date online. Exciting right? In this article, we will let you in on a few reasons why online dating is worth a try.",
    ],
    subContents: [
      {
        image: null,
        topic: "Why You Should Try Online Dating",
        supTopic: "",
        contents: [
          "Here are five reasons why you should consider online dating:",
        ],
      },
      {
        image: null,
        topic: "Better access and exposure",
        supTopic: "",
        contents: [
          "The kind of people you meet online won’t be the same kind you’d randomly meet in church, at the office or coffee shop. Using online dating platforms exposes you to people within and outside your reach. You get to meet people from different cultures, religious beliefs and sexual orientations.",
        ],
      },
      {
        image: null,
        topic: "Ease of use",
        supTopic: "",
        contents: [
          "You only need a mobile phone and an internet connection to start dating online. Once you’ve set up your profile on the platform, you can include any information you’d like to show the public and you’ll be ready to start meeting new people in no time. As soon as you have set up your profile, you can have fun looking for potential matches on the platform.",
        ],
      },
      {
        image: null,
        topic: "Better social life",
        supTopic: "",
        contents: [
          "Whether you are dating for fun or dating to be lifelong partners, dating platforms expose you to more people. It’s also a great advantage for shy people as you get enough time to prepare to meet your date.",
          "You can easily have meaningful conversations with other users on your preferred dating platforms. This way, you’ll build better relationships easily (romantic and non-romantic).",
        ],
      },
      {
        image: null,
        topic: "Safety",
        supTopic: "",
        contents: [
          "At least 86 percent of women check out their love interest on social media before their first meeting while 22 percent of men are more likely to google their date in search of scandalous data. This is quite telling since the only place you can get accurate information is the internet. You get to find out a lot of background information about your love interest which gives you a sense of security and safety.",
        ],
      },
      {
        image: null,
        topic: "Summary",
        supTopic: "",
        contents: [
          "According to Pew Research, twelve percent of Americans have gotten married or started a committed relationship with someone they first met through a dating site or app. There are many benefits of dating online and we have discussed only a few of them in this article.",
          "Dating online gives you a reasonable amount of control over your love life. You determine when you are open to dating, who you want to date, and when or how you would like the relationship to start. You have a wide pool of potential partners to choose from so it’s up to you to make a selection that will be near perfect for you. Spowse gives you all this and more.",
          "Sign up to join our waitlist, we’ll let you know when we launch.",
        ],
      },
    ],
  }
];
