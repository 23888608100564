import React from "react";
import Header from "../../../header/Header";
import { BtnCon, Container, ImgCon, Wrapper } from "./Hero_styles";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../../core/utils/app_images";
import { theme } from "../../../../theme/theme";

const Hero = () => {
  return (
    <Wrapper>
      <Header />
      <Container>
        <Heading3
          color={`${theme.colors.secondary}`}
          fontWeight="900"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.2px"
          fs=" 12px"
          lh="15px"
          textAlign="center"
        >
          ARE YOU THE MISSING PIECE AT SPOWSE?
        </Heading3>
        <Heading1
          color={`${theme.colors.primary}`}
          fontWeight="900"
          fontSize="60px"
          lineHeight="75px"
          letterSpacing="0.8px"
          fs="30px"
          lh="38px"
          margin="0.5rem 0 1rem"
          textAlign="center"
        >
          Take Your Seat At The Future Of Love{" "}
        </Heading1>
        <CommonText
          color="#282D2B"
          fontWeight="400"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          textAlign="center"
          width="900px"
          wid="100%"
          margin="0 auto"
        >
          Think you’ll love to help others find their special someone? Are you
          passionate about building connections for the African community?
          Thrive in a fun and supportive work environment at Spowse
        </CommonText>
        <BtnCon>
          <SpowseButton width="239px">Apply for open positions</SpowseButton>
        </BtnCon>
        <ImgCon>
          <SpowseImage src={AppImages.teamwork} alt="img" />
        </ImgCon>
      </Container>
    </Wrapper>
  );
};

export default Hero;
