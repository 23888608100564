import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import Hero from './components/hero/Hero'
import Content from './components/community_guidelines/Content'
import { Helmet } from 'react-helmet-async'

const Guidelines = () => {
  return (
    <div>
      <Helmet>
        <title>Community Guidelines - Spowse</title>
        <meta
          name="description"
          content="Read the Spowse Community Guidelines to ensure a safe and respectful experience for all users. Learn about our standards for behavior, privacy, and communication on the platform. Together, we create a positive space for meaningful connections."
        />

        <meta charSet="utf-8" />
        <link rel="canonical" href={`/community-guidelines`} />
      </Helmet>
      <Header />
      <Hero />
      <Content />
      <Footer />
    </div>
  )
}

export default Guidelines
