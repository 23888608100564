import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import Content from './components/content/Content'
import RelatedPost from './components/related-post/RelatedPost'

const Blogpost = () => {
  return (
    <div>
      <Header />
      <Content />
      <RelatedPost/>
      <Footer />
    </div>
  )
}

export default Blogpost
