import React from "react";
import Top from "./components/top/Top";
import Header from "../header/Header";
import { ProductWrapper } from "./Product_styles";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet-async";

const Product = () => {
  return (
    <main>
      <Helmet>
        <title>Product - Spowse</title>
        <meta
          name="description"
          content="Unlock exclusive features and enhance your Spowse experience with our subscription plans. Discover premium tools to boost your profile, find matches faster, and enjoy an ad-free experience. Choose the subscription that's right for you and elevate your dating journey on Spowse."
        />

        <meta charSet="utf-8" />
        <link rel="canonical" href={`/products`} />
      </Helmet>
      <ProductWrapper>
        <Header />
        <Top />
      </ProductWrapper>
      <Footer />
    </main>
  );
};

export default Product;
