import React from "react";
import {
  Box,
  Btn,
  Desc,
  Heading,
  HeroContent,
  Left,
  Right,
  SmallText,
  Span,
  Wrapper,
} from "./Hero_styles";
import Header from "../../../header/Header";
import ImageRotation from "../cicled_image_animation/circled_animation";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <Wrapper>
      <HeroContent>
        <Header />
        <Box>
          <Left>
            <SmallText> WHAT ARE WE DOING?</SmallText>
            <Heading>
              Building A <Span>Love-Filled</Span> Space for Africans
            </Heading>
            <Desc>
              We want to help Africans build meaningful relationships the easier
              and safer way. {`We’ve`} made it our priority to make dating
              pleasant and hassle-free.
            </Desc>
            <Link
              to="https://play.google.com/store/apps/details?id=io.spowse.androidApp"
              style={{ textDecoration: "none", color: "inherit" }}
              target="_blank"
            >
              <Btn>Download The App</Btn>
            </Link>
          </Left>
          <Right>
            <ImageRotation />
          </Right>
        </Box>
      </HeroContent>
    </Wrapper>
  );
};

export default Hero;
