import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const Wrapper = styled.section`
  width: 100%;
  margin: 8rem 0 5rem 0;
  background-color: ${theme.colors.white};
`;

export const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 0rem 0rem;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  align-items: center;
  @media (max-width: 800px) {
    /* padding: 2rem; */
  }
`;

export const SmallText = styled.p`
  color: ${theme.colors.secondary};
  font-weight: 900;
  font-size: 16px;
  font-family: '"Kumbh Sans", sans-serif';
  letter-spacing: 0.8px;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const Heading = styled.h1`
  color: ${theme.colors.primary};
  font-weight: 900;
  font-size: 50px;
  font-family: '"Kumbh Sans", sans-serif';
  line-height: 53px;
  letter-spacing: 0.8px;
  margin: 0.5rem 0 1rem 0;
  text-align: center;
  @media (max-width: 800px) {
    font-size: 28px;
    line-height: normal;
    padding: 0 2rem;
  }
`;

export const Desc = styled.p`
  color: #282d2b;
  margin-bottom: 2rem;
  font-family: "geist-light";
  letter-spacing: 0.8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  width: 650px;
  text-align: center;
  @media (max-width: 800px) {
    padding: 0 2rem;
    width: 100%;
  }

`;

export const LogoGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 9rem;
  row-gap: 2rem;
  width: 70%;
  @media (max-width: 1407px) {
    /* width: 90%; */
    width: 60%;
    column-gap: 5rem ;
  }

  @media (max-width: 1100px) {
    /* width: 90%; */
    width: 70%;
    column-gap: 5rem ;
  }
  @media (max-width: 991px) {
    width: 90%;
  }
  @media (max-width: 800px) {
    /* width: 100%; */
    width: 80%;
  }
  @media (max-width: 600px) {
    width: 100%;
    column-gap: 4rem;
  }
  @media (max-width: 450px) {
    column-gap: 3rem;
  }
  @media (max-width: 395px) {
    column-gap: 1.5rem;
  }
`;

export const LogoItem = styled.div`
`;
