import React, { useEffect, useState } from "react";
import { CustomLink, IconCon, Left, Right, Wrapper } from "./styles";
import { SpowseImage } from "../../core/common/reuseables/spowse_image";
import { AppIcons } from "../../core/utils/app_icons";
import { useMatch, useResolvedPath } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);
  const [sidebar, setSideBar] = useState(false);

  const showSidebar = () => {
    setSideBar(!sidebar);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 30;

      setScrolling(scrollTop > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Wrapper>
        <Left>
          <a href="/">
            <SpowseImage
              src={AppIcons.spowseLogo}
              alt="img"
              w="120px"
              m="10px 0 0 0 "
            />
          </a>
        </Left>
        <Right>
          <CustomLinks onTap={() => {}} to="/product">
            Product
          </CustomLinks>
          <CustomLinks onTap={() => {}} to="/about">
            About
          </CustomLinks>
          <CustomLinks onTap={() => {}} to="/safety">
            Safety
          </CustomLinks>
          <CustomLinks onTap={() => {}} to="/trybe">
            Trybe
          </CustomLinks>
          <CustomLinks onTap={() => {}} to="/contact-us">
            Contact Us
          </CustomLinks>
        </Right>
        <IconCon onClick={showSidebar}>
          <SpowseImage
            src={AppIcons.hamburger}
            display="none"
            dis="block"
            width="25px"
          />
        </IconCon>
      </Wrapper>
      {<Sidebar active={setSideBar} sidebar={sidebar} />}
    </>
  );
};

export default Header;

interface CustomLinksProps {
  to: string;
  children: React.ReactNode;
  onTap?: any;
  scrolling?: any;
}

function CustomLinks({ to, children, onTap, scrolling }: CustomLinksProps) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <CustomLink to={to} active={isActive} onClick={onTap}>
      {children}
    </CustomLink>
  );
}
