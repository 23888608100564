export const relatedArticles = [
    {
        image: "/assets/images/related11.svg",
        category: "QUIZZES",
        title: "Are You Ready For A Relationship? Take The Quiz!",
        date: "21 October 2024",
        id: "fd157a4c-1149-454a-82e1-7bdde86d377d"
    },     {
        image: "/assets/images/related2.svg",
        category: "QUIZZES",
        title: "What Afrobeat Song Depicts Your Dating Life?",
        date: "21 October 2024",
        id: "9dea9db8-09c1-4655-8e9d-88949565a720"
    }   , {
        image: "/assets/images/related3.svg",
        category: "SUCCESS STORIES",
        title: "Dating In The Digital Age - What Experts Think",
        date: "21 October 2024",
        id: "f73995f9-b5ae-46f1-9667-426bcff076c9"
    }
]