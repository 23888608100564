export const discoverLove = [
  {
    image: "/assets/images/d2.png",
    title: "Cupid Time Connections",
    content:
      "Seize Nearby Connections with Spowse's Cupid Time. Wherever you roam – mall, lounge, or gym – explore profiles of nearby users. Cupid works her magic, connecting you in real-time with nearby potentials.",
  },
  {
    image: "/assets/images/d1.png",
    title: "Spowse’s Love Compass",
    content:
      "Navigate Your Love Journey with Spowse's Love Compass. Discover your ideal connections and explore exciting alternatives simultaneously with our innovative 'For You' and 'Explore' features.",
  },
  {
    image: "/assets/images/d3.png",
    title: "Slides And Heartsyncs",
    content:
      "We combine your interests and culture to curate matches for you. Explore and connect with potential partners using our intuitive sliding feature, ensuring finding love is both enjoyable and meaningful.",
  },
];

export const feedBacks = [
  {
    feedBack:
      "“I have been on Spowse for a few months and it’s been an amazing experience! The app feels easy to use and helps me find people who share my value and interests. I love how I can connect with someone nearby and explore matches fro different counties. It feels real and refreshing.”",
    name: " Jessica Lytah",
  },
  {
    feedBack:
      "Spowse has been a game-changer for me. The sign-up process was straightforward, and I liked the personality test that helps in finding compatible matches. The quality of profiles here is quite high compared to other dating sites I've used. I've had some great conversations and met a few interesting people.",
    name: "Michael Tithans",
  },
  {
    feedBack:
      "Spowse offers a refreshing approach to online dating. The platform feels secure and reliable, and I appreciate the verification process for profiles. It gives an added layer of trust that is often missing on other dating sites. The matching algorithm seems to work well – I’ve had several promising matches.",
    name: "Emily Ryhna",
  },
];

export const homeTrybeSection = [
  {
    image: "/assets/images/trybe13.png",
    category: "DATING TIPS",
    topic: "To-Do List For Singles Looking for Successful Relationships",
    createdAt: "26 May 2023",
  },
  {
    image: "/assets/images/trybe14.png",
    category: "SPOWSE UPDATES",
    topic: "Get Familiar With Cupid Haven And Her Plans For You",
    createdAt: "26 May 2023",
  },
  {
    image: "/assets/images/trybe12.png",
    category: "SUCCESS STORIES",
    topic: "Daisy Shares Her Story With Tamuno Courtesy Of Spowse",
    createdAt: "26 May 2023",
  },
];
