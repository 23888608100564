import styled from "styled-components";
import { theme } from "../../theme/theme";
import { Link } from "react-router-dom";

interface HeaderProbs {
  active?: any;
  backgroundColor?: any;
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between !important;
  background-color: transparent;
  align-items: center;
  padding: 2.5rem 4rem;
  max-width: 1500px;
  margin: 0 auto;
  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const Left = styled.div``;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const CustomLink = styled(Link)<HeaderProbs>`
  font-size: 16px;
  color: ${(probs) =>
    //edit to be black or white based on hero section
    probs.active ? theme.colors.secondary : `${theme.colors.primary}`};
  text-decoration: none;
  font-family: "geist-light";

  &:hover {
    color: ${theme.colors.secondary};
    transform: scale(1.1);
    transition: 300ms all ease-in;
  }
`;

export const IconCon = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;
