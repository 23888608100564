import React from "react";
import {
  Container,
  ContentFlex,
  Down,
  FeatureTexts,
  LeftImgCon,
  Right,
  Wrapper,
} from "./Features_styles";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../../core/utils/app_images";
import { safetyfeature } from "../../data/data";
import { theme } from "../../../../theme/theme";

const Features = () => {
  return (
    <Wrapper>
      <Container>
        <Heading3
          color={`${theme.colors.secondary}`}
          fontWeight="700"
          fontSize="16px"
          lineHeight="24px"
          letterSpacing="0.8px"
          fs=" 12px"
          lh="15px"
          textAlign="center"
        >
          SAFETY FEATURES
        </Heading3>
        <Heading1
          color={`${theme.colors.primary}`}
          fontWeight="700"
          fontSize="60px"
          lineHeight="75px"
          letterSpacing="0.8px"
          fs="30px"
          lh="38px"
          margin="4px 0 8px 0"
          textAlign="center"
        >
          Priotizing Your Safety One Feature At A Time
        </Heading1>
        <CommonText
          color="#282D2B"
          fontWeight="400"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          textAlign="center"
          width="860px"
          w="100%"
          margin="0 auto"
        >
          Discover the steps we take to ensure your safety on Spowse. From
          robust verification processes to secure messaging, we have got you
          covered every step of the way.
        </CommonText>
        <ContentFlex>
          <LeftImgCon>
            <SpowseImage src={AppImages.safetyFeature} alt="img" width="100%" />
          </LeftImgCon>
          <Right>
            {safetyfeature.slice(0, 3).map((item, index) => (
              <FeatureTexts key={index}>
                <Heading3
                  color={`${theme.colors.primary}`}
                  fontWeight="700"
                  fontSize="24px"
                  lineHeight="35px"
                  letterSpacing="0.8px"
                  fs="20px"
                  lh="30px"
                  margin="4px 0 4px 0"
                >
                  {item.title}{" "}
                </Heading3>
                <CommonText
                  color="#282D2B"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="20px"
                  letterSpacing="0.8px"
                >
                  {" "}
                  {item.desc}
                </CommonText>
              </FeatureTexts>
            ))}
          </Right>
        </ContentFlex>
        <Down>
          {safetyfeature.slice(3, 7).map((item, index) => (
            <FeatureTexts key={index}>
              <Heading3
                color={`${theme.colors.primary}`}
                fontWeight="700"
                fontSize="24px"
                lineHeight="35px"
                letterSpacing="0.8px"
                fs="20px"
                lh="30px"
                margin="4px 0 4px 0"
              >
                {item.title}{" "}
              </Heading3>
              <CommonText
                color="#282D2B"
                fontWeight="400"
                fontSize="16px"
                lineHeight="20px"
                letterSpacing="0.8px"
              >
                {" "}
                {item.desc}
              </CommonText>
            </FeatureTexts>
          ))}
        </Down>
      </Container>
    </Wrapper>
  );
};

export default Features;
