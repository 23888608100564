import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../presentations/home/Home";
import About from "../presentations/about/About";
import Product from "../presentations/product/Product";
import ContactUs from "../presentations/contact-us/ContactUs";
import Help from "../presentations/help-and-support/Help";
import Request from "../presentations/submit-a-request/Request";
import Faqs from "../presentations/faqs/Faqs";
import SuccessStory from "../presentations/success-story/SuccessStory";
import Trybe from "../presentations/trybe/Trybe";
import Career from "../presentations/career/Career";
import Guidelines from "../presentations/guidelines/Guidelines";
import BlogDetails from "../presentations/blog_details/BlogDetails";
import PrivacyPolicy from "../presentations/privacy-policy/PrivacyPolicy";
import Safety from "../presentations/safety/Safety";
import Blogpost from "../presentations/blog-post/Blogpost";
import Application from "../presentations/submit-an-application/Application";

type RoutePathsProps = {};

const RoutePaths: React.FC<RoutePathsProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/product" element={<Product />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/contact-us/help-and-support" element={<Help />} />
      <Route path="/contact-us/submit-a-request" element={<Request />} />
      <Route path="/faqs" element={<Faqs />} />
      <Route path="/success-story" element={<SuccessStory />} />
      <Route path="/trybe" element={<Trybe />} />
      <Route path="/trybe/:id" element={<Blogpost />} />
      <Route path="/career" element={<Career />} />
      <Route path="/community-guidelines" element={<Guidelines />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/safety" element={<Safety />} />
      <Route path="/career/submit-an-application" element={<Application />} />
    </Routes>
  );
};
export default RoutePaths;
