import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const TrybeSectionWrapper = styled.section`
  width: 100%;
  background: ${theme.colors.lightBlue};
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const TrybeSectionContent = styled.div`
  width: 100%;
  max-width: 1500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 5rem 4rem;
  text-align: center;

  @media (max-width: 800px) {
    padding: 3rem 1.5rem;
  }
`;

export const TrybeSectionGridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 1.1rem;
  row-gap: 2rem;
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const TrybeSectionGridContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
  gap: 10px;

  & > :nth-child(1),
  > :nth-child(3) {
    margin-bottom: 10px;
  }

  & > :nth-child(3) {
    width: 90%;
  }
`;
