import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Grid from "./components/Grid/Grid";
import Hero from "./components/hero/Hero";
import { Helmet } from "react-helmet-async";

const Trybe = () => {
  return (
    <main>
      <Helmet>
        <title>Trybe - Spowse</title>
        <meta
          name="description"
          content="Explore the Spowse blog for dating tips, relationship advice, success stories, and more. Stay updated on the latest trends in the world of dating and love. Our expert writers offer insights to enhance your journey to finding meaningful connections."
        />

        <meta charSet="utf-8" />
        <link rel="canonical" href={`/faqs`} />
      </Helmet>
      <Header />
      <Hero />
      <Grid />
      <Footer />
    </main>
  );
};

export default Trybe;
