import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../theme/theme";

interface HeaderProbs {
  active?: any;
  backgroundColor?: any;
}  


export const HeaderWrapper = styled.section<HeaderProbs>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 299;
  left: 0;
  right: 0;
  background: ${(probs) => probs.backgroundColor};
`;

export const HeaderContent = styled.div`
  width: 100%;
  max-width: 1500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 4rem;
  @media (max-width: 800px) {
    padding: 2rem 1.5rem;
  }
`;

export const HeaderContentLeftSection = styled.div``;

export const HeaderContentRightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  @media (max-width: 800px) {
    display: none;
  }
`;

//!links styles
export const CustomLink = styled(Link)<HeaderProbs>`
  font-size: 16px;
  color: ${(probs) =>
  //edit to be black or white based on hero section
    probs.active ? theme.colors.secondary : `${theme.colors.white}`};
  text-decoration: none;
  font-family: "geist-light";

  &:hover {
    color: ${theme.colors.secondary};
    transform: scale(1.1);
    transition: 300ms all ease-in;
  }
`;
