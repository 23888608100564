import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { AppImages } from '../../../../core/utils/app_images';

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const CircleContainer = styled.div`
  width: 500px;
  height: 500px;
  border-radius: 50%;
  position: relative;

  @media(max-width: 1332px){
    width: 400px;
    height: 400px;
  }

  @media (max-width: 1053px) {
    width: 500px;
    height: 500px;
  }

  @media (max-width: 735px) {
    width: 360px;
    height: 360px;
  }
`;

const RotatingImagesContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${rotate} 30s linear infinite;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15); */
  user-select: none;
  -webkit-user-drag: none;

  @media(max-width: 1332px){
    width: 70px;
    height: 70px;
  }

  @media (max-width: 1053px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 735px) {
    width:60px;
    height: 60px;
  }

  @media (max-width: 344px) {
    width:50px;
    height: 50px;
  }
`;

const Image1 = styled.img`
  width: 320px;
  height: 320px;
  border-radius: 50%;
  position: absolute;
  user-select: none;
  -webkit-user-drag: none;

  @media(max-width: 1332px){
    width: 200px;
    height: 200px;

  }

   @media (max-width: 1053px) {
    width: 320px;
    height: 320px;
  }

  @media (max-width: 735px) {
    width: 200px;
    height: 200px;
  }

  @media(max-width: 500px){
    width: 150px;
    height: 150px;

  }


 
`;

const images = [
  { angle: 0, src: '/assets/images/aboutimg1.svg', alt: 'Image 1' },
  { angle: 60, src: '/assets/images/aboutimg2.svg', alt: 'Image 2' },
  { angle: 120, src: '/assets/images/aboutimg3.svg', alt: 'Image 3' },
  { angle: 180, src: '/assets/images/aboutimg4.svg', alt: 'Image 4' },
  { angle: 240, src: '/assets/images/aboutimg5.svg', alt: 'Image 5' },
  { angle: 300, src: '/assets/images/aboutimg6.svg', alt: 'Image 6' },
];

const ImageRotation = () => {
  const [screenSizeNumber, setScreenSizeNumber] = useState<number>(250);
  const [radiusValue, setRadius] = useState(200);

  useEffect(() => {
    const updateNumberBasedOnScreenSize = () => {
      const width = window.innerWidth;
      if (width <= 344) {
        setScreenSizeNumber(175);
        setRadius(100);
      } else if (width <= 368) {
        setScreenSizeNumber(180);
        setRadius(100);
      } else if (width <= 388) {
        setScreenSizeNumber(185);
        setRadius(100);
      } else if (width <= 400) {
        setScreenSizeNumber(190);
        setRadius(120);
      } else if (width <= 500) {
        setScreenSizeNumber(180);
        setRadius(140);
      } else if (width <= 735) {
        setScreenSizeNumber(180);
        setRadius(140);
      } else if (width <= 1053) {
        setScreenSizeNumber(250);
        setRadius(200);
      } else if (width <= 1332) {
        setScreenSizeNumber(200);
        setRadius(150);
      }
    };
    updateNumberBasedOnScreenSize();
    window.addEventListener('resize', updateNumberBasedOnScreenSize);
    return () => window.removeEventListener('resize', updateNumberBasedOnScreenSize);
  }, []);

  const calculatePosition = (angle: number) => {
    const radius = radiusValue;
    const radian = (angle * Math.PI) / 180;
    const x = screenSizeNumber + radius * Math.cos(radian) - 40;
    const y = screenSizeNumber + radius * Math.sin(radian) - 40;
    return { top: `${y}px`, left: `${x}px` };
  };

  return (
    <CircleContainer>
      <RotatingImagesContainer>
        {images.map((image, index) => {
          const position = calculatePosition(image.angle);
          return (
            <Image
              key={index}
              src={image.src}
              alt={image.alt}
              style={{ top: position.top, left: position.left }}
            />
          );
        })}
      </RotatingImagesContainer>
      <Image1
        src={AppImages.aboutHero1}
        alt="Center Image"
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      />
    </CircleContainer>
  );
};

export default ImageRotation;
