import React from "react";
import {
  DiscoverLoveContent,
  DiscoverLoveGridChild,
  DiscoverLoveGridContainer,
  DiscoverLoveWrapper,
} from "./Discover_love_styles";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { discoverLove } from "../../controllers/logic";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { theme } from "../../../../theme/theme";

type DiscoverLoveProps = {};

const DiscoverLove: React.FC<DiscoverLoveProps> = () => {
  return (
    <DiscoverLoveWrapper>
      <DiscoverLoveContent>
        <Heading3
          color={`${theme.colors.lightGreen1}`}
          fontSize="16px"
          lineHeight="20px"
          fs="12px"
          lh="18px"
        >
          DISCOVER LOVE THROUGH FEATURES
        </Heading3>
        <Heading1
          color={`${theme.colors.primary}`}
          fontSize="50px"
          // lineHeight="63px"
          margin="0"
          letterSpacing="0.8px"
          textAlign="center"
          fs="28px"
          lh="34px"
        >
          You Are A Feature Away From Your Ideal Partner
        </Heading1>
        <CommonText
          fontFamily="geist-regular"
          color={`${theme.colors.primary}`}
          fontSize="16px"
          lineHeight="19.2px"
          textAlign="center"
          letterSpacing="0.2px"
        >
          Our features on Spowse makes it easy for you to connect, no matter
          where you are or what you’re looking for. With unique options tailored
          for African singles, you’re never far from finding your ideal partner.
        </CommonText>
        <DiscoverLoveGridContainer>
          {discoverLove.map((e, index) => {
            return (
              <DiscoverLoveGridChild key={index}>
                <SpowseImage src={e.image} alt="img" />
                <Heading3
                  color={`${theme.colors.primary}`}
                  fontSize="24px"
                  lineHeight="29.88px"
                  letterSpacing="0.8px"
                >
                  {e.title}
                </Heading3>
                <CommonText
                  fontFamily="geist-regular"
                  color={`${theme.colors.primary}`}
                  fontSize="16px"
                  lineHeight="20pxpx"
                  letterSpacing="0.2px"
                >
                  {e.content}
                </CommonText>
              </DiscoverLoveGridChild>
            );
          })}
        </DiscoverLoveGridContainer>
      </DiscoverLoveContent>
    </DiscoverLoveWrapper>
  );
};
export default DiscoverLove;
