import ApiService from '../api';
import { ApiUrl } from '../api_url';

interface BlogSection {
  heading: string;
  content: string;
  image: string;
}

interface BlogPost {
  _id: string;
  title: string;
  category: string;
  coverImage: string;
  publishDate: string;
  sections: BlogSection[];
  subTitle: string;
  thumbnail: string;
}

interface BlogApiResponse {
  success: boolean;
  message: string;
  data: {
    blog: BlogPost;
  };
}

class BlogPostService {
  private apiService: ApiService;

  constructor() {
    this.apiService = new ApiService();
  }

  async getBlogPostById(id: string): Promise<{
    success: boolean;
    blog: BlogPost | null;
    message?: string;
  }> {
    const response = await this.apiService.getData<BlogApiResponse>(ApiUrl.BLOG_DETAILS(id));
    return {
      success: response.success,
      blog: response.data?.data.blog || null,
      message: response.message || 'No message provided',
    };
  }
}

export default new BlogPostService();
