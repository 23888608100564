export class AppIcons{
    static spowseLogo = "/assets/icons/new_logo_green.svg";
    static spowseWhiteLogo = "/assets/icons/spowse_white_logo.svg";
    static facebook = "/assets/icons/facebook.svg";
    static instagram = "/assets/icons/instagram.svg";
    static linkedIn = "/assets/icons/linkedin.svg";
    static youtube = "/assets/icons/youtube.svg";
    static twitter = "/assets/icons/twitter.svg";
    static rightArrow = "/assets/icons/right_arrow.svg";
    static leftArrow = "/assets/icons/left_arrow.svg";
    static hamburger = "/assets/icons/hamburger.svg"
    static dot = "/assets/icons/dot.svg"
    static logoHome = "/assets/icons/new_logo_white.svg"
    static hamburgerHome = "/assets/images/hamburger_home.svg"
}