import styled from "styled-components";

export const Wrapper = styled.section`
  background-color: #eff7f4;
  @media (max-width: 800px) {
    /* margin: 1rem 0; */
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 6rem 4rem;
  display: flex;
  align-items: center;
  gap: 51px;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 50px;
    padding: 2rem 1.5rem;
    max-width: 100%;
  }
`;

export const Left = styled.div`
  flex: 1;
`;

export const Right = styled.div`
  flex: 1;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
