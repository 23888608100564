export const values = [
  {
    icon: "/assets/images/love.svg",
    title: "United By Love",
    desc: "At Spowse. We believe in love and support. From valued team members to loved-up users,when the journey gets tough, we join hands together and ride the storm till it's over.",
  },  {
    icon: "/assets/images/work.svg",
    title: "Shape Your Work",
    desc: "Spowse respects your ownership and empowers excellence. Come thrive in an environment that lets you take charge of your work while we support your success.",
  },  {
    icon: "/assets/images/grow.svg",
    title: "Learn. Grow. Thrive",
    desc: "We invest in both your personal and professional growth. We’re conscious of the fact that we can’t achieve this without evolving and embracing positive changes and opportunities.",
  },  {
    icon: "/assets/images/inclusion.svg",
    title: "Beyond Inclusion",
    desc: "We build a workspace that reflects the vibrant African communities we serve. It is our desire that every employee is able to excel at work. We see, hear and value all voices.",
  },  {
    icon: "/assets/images/excellence.svg",
    title: "Aim High. Achieve Excellence",
    desc: "From the hiring process to onboarding, we are dedicated to setting high standards and achieving outstanding outcomes. We shoot for the stars, join us and reach your full potential.",
  },
];
   