import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 4rem;
  @media (max-width: 800px) {
    padding: 0 2rem;
  }
`;

export const Container = styled.div`
  max-width: 70%;
  margin: 0 auto;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`;
