import React, { FC } from "react";
import "./style.css";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { SpowseImage } from "../../core/common/reuseables/spowse_image";
import { AppIcons } from "../../core/utils/app_icons";
import { IoClose } from "react-icons/io5";
import {
  LogoClose,
  NavLink,
  CustomLink,
  Bottom,
  BtnCon,
  Socials,
  SocialLink,
} from "./styles";
import { SpowseButton } from "../../core/common/reuseables/spowse_button";
import { theme } from "../../theme/theme";

type Props = {
  active: any;
  sidebar: boolean;
};

const Sidebar: FC<Props> = ({ active, sidebar }) => {
  const closeSideBar = () => {
    active(false);
  };

  return (
    <>
      {/* <div   className={`main ${sidebar ? "open" : "close"}`} onClick={closeSideBar}></div> */}
      <div className={`container ${sidebar ? "open" : "close"}`}>
        <LogoClose>
          <a href="/">
            <SpowseImage
              src={AppIcons.spowseLogo}
              alt="img"
              w="120px"
              m="10px 0 0 0 "
            />
          </a>
          <IoClose onClick={closeSideBar} style={{ fontSize: "25px" }} />
        </LogoClose>
        <NavLink>
          <CustomLinks onTap={() => {}} to="/product">
            Product
          </CustomLinks>
          <CustomLinks onTap={() => {}} to="/about">
            About
          </CustomLinks>
          <CustomLinks onTap={() => {}} to="/safety">
            Safety
          </CustomLinks>
          <CustomLinks onTap={() => {}} to="/trybe">
            Trybe
          </CustomLinks>
          <CustomLinks onTap={() => {}} to="/contact-us">
            Contact Us
          </CustomLinks>
        </NavLink>
        <Bottom>
          <BtnCon>
            <Link
              to="https://play.google.com/store/apps/details?id=io.spowse.androidApp"
              style={{ textDecoration: "none", color: "inherit" }}
              target="_blank"
            >
              <SpowseButton
                backgroundColor={`${theme.colors.lightGreen}`}
                width="270px"
                textColor={`${theme.colors.primary}`}
              >
                Get the App
              </SpowseButton>
            </Link> 
          </BtnCon>
          <Socials>
            <SocialLink>
              {" "}
              <SpowseImage src={AppIcons.twitter} alt="icon" />
            </SocialLink>
            <SocialLink>
              <SpowseImage src={AppIcons.facebook} alt="icon" />
            </SocialLink>
            <SocialLink>
              <SpowseImage src={AppIcons.instagram} alt="icon" />
            </SocialLink>
            <SocialLink>
              <SpowseImage src={AppIcons.youtube} alt="icon" />
            </SocialLink>
            <SocialLink>
              <SpowseImage src={AppIcons.linkedIn} alt="icon" />
            </SocialLink>
          </Socials>
        </Bottom>
      </div>
    </>
  );
};

export default Sidebar;

interface CustomLinksProps {
  to: string;
  children: React.ReactNode;
  onTap?: any;
  scrolling?: any;
}

function CustomLinks({ to, children, onTap, scrolling }: CustomLinksProps) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <CustomLink to={to} active={isActive} onClick={onTap}>
      {children}
    </CustomLink>
  );
}
