import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const Wrapper = styled.section`
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    margin: 4rem 1rem 2rem 1rem;
    @media (max-width: 800px) {
   margin: 2rem 1.5rem;
  }
`

 
export const TabsCon= styled.div`
    margin: 49px auto 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(9, 152, 56, 0.1);
    padding: 4px;
    border-radius: 30px;
    width: fit-content;
    @media (max-width: 800px) {
   margin: 2rem auto;
  }
` 

export const Tabs = styled.div<{ isActive: any }>`
    font-family:"geist-light" ;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.8px;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: ${({ isActive }) => isActive ? theme.colors.secondary: "transparent"};
    color: ${({ isActive }) => isActive ? "#FFF" : theme.colors.primary};
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    &:hover {
        background-color: ${({ isActive }) => isActive ? theme.colors.secondary : "rgba(9, 152, 56, 0.1)"};
    }

    @media (max-width: 800px) {
    font-size: 12px;
    padding: 10px 12px;
  }
`

export const TabContentWrapper = styled.div``