import React, { useState } from "react";
import {
  BtnCon,
  Container,
  Form,
  Input,
  InputCon,
  InputRow,
  Label,
  TextArea,
  Wrapper,
} from "./form_styles";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { theme } from "../../../../theme/theme";
import ContactService from "../../../../core/api/services/contact";

const RequestForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    howCanHelp: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      const response = await ContactService.submitContactUsForm(formData);
      if (response.success) {
        setMessage("Your request has been submitted successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          subject: "",
          howCanHelp: "",
          description: "",
        });
      } else {
        setMessage(`Error: ${response.message}`);
      }
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Heading3
          fontSize="16px"
          fontWeight="900"
          lineHeight="20px"
          letterSpacing="0.8px"
          fs="12px"
          lh="15px"
          color={`${theme.colors.secondary}`}
        >
          WELCOME
        </Heading3>
        <Heading1
          fontSize="60px"
          fontWeight="900"
          lineHeight="75px"
          letterSpacing="0.8px"
          fs="30px"
          lh="38px"
          color={`${theme.colors.primary}`}
          margin="0.8rem 0"
        >
          Submit A Request
        </Heading1>
        <Form onSubmit={handleSubmit}>
          <InputRow>
            <InputCon>
              <Label>First name</Label>
              <Input
                type="text"
                required
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </InputCon>
            <InputCon>
              <Label>Last name</Label>
              <Input
                type="text"
                required
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </InputCon>
          </InputRow>
          <InputRow>
            <InputCon>
              <Label>Your email address</Label>
              <Input
                placeholder="Daisyohikhuare20@gmail.com"
                type="email"
                required
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </InputCon>
          </InputRow>
          <InputRow>
            <InputCon>
              <Label>Subject (optional)</Label>
              <Input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
              />
            </InputCon>
          </InputRow>
          <InputRow>
            <InputCon>
              <Label>How can we help you?</Label>
              <Input
                type="text"
                required
                name="howCanHelp"
                value={formData.howCanHelp}
                onChange={handleInputChange}
              />
            </InputCon>
          </InputRow>
          <InputRow>
            <InputCon>
              <Label>Description</Label>
              <TextArea
                required
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </InputCon>
          </InputRow>
          <CommonText
            fontSize="14px"
            fontWeight="400"
            lineHeight="17px"
            letterSpacing="0.8px"
            color="#5C6360"
            margin="-15px 0 3rem 0"
          >
            Please enter the details of your request, providing as much
            information as possible
          </CommonText>
          <BtnCon>
            <SpowseButton type="submit" width="178px">
              {loading ? "Submitting..." : "Submit"}
            </SpowseButton>
          </BtnCon>
          {message && (
            <CommonText
              fontSize="14px"
              fontWeight="400"
              lineHeight="17px"
              letterSpacing="0.8px"
              color={message.startsWith("Error") ? "red" : "green"}
            >
              {message}
            </CommonText>
          )}
        </Form>
      </Container>
    </Wrapper>
  );
};

export default RequestForm;
