import { Container, FlexCon, ImgCon, Items, Wrapper } from "./Values_styles";
import {
  CommonText,
  Heading1,
  Heading3,
  Heading4,
} from "../../../../core/common/reuseables/text_styles";
import { values } from "./data/data";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { theme } from "../../../../theme/theme";

const Values = () => {
  return (
    <Wrapper>
      <Container>
        <Heading3
          color={`${theme.colors.secondary}`}
          fontWeight="900"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.2px"
          fs=" 12px"
          lh="15px"
          textAlign="center"
        >
          THE SPOWSE VALUES
        </Heading3>
        <Heading1
          color={`${theme.colors.primary}`}
          fontWeight="900"
          fontSize="50px"
          lineHeight="63px"
          letterSpacing="0.8px"
          fs="28px"
          lh="35px"
          margin="0.5rem 0 1rem"
          textAlign="center"
        >
          Discover The Values That Drives Us At Spowse
        </Heading1>
        <CommonText
          color={`${theme.colors.primary}`}
          fontWeight="400"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          textAlign="center"
        >
          The place where passion meets purpose and building connections starts
          within. Discover our values that build strong African communities.
        </CommonText>
        <FlexCon>
          {values.map((item, index) => (
            <Items key={index}>
              <ImgCon>
                <SpowseImage src={item?.icon} alt="img" width="80px" w="70px"  />
              </ImgCon>
              <Heading4
                textAlign="center"
                fontWeight="900"
                fontSize="24px"
                lineHeight="30px"
                margin="16px 0 8px"
                letterSpacing="0.8px"
              >
                {item?.title}
              </Heading4>
              <CommonText
                width="380px"
                textAlign="center"
                fontWeight="400"
                fontSize="16px"
                lineHeight="20px"
                letterSpacing="0.8px"
                contentWid="100%"
              >
                {item?.desc}
              </CommonText>
            </Items>
          ))}
        </FlexCon>
      </Container>
    </Wrapper>
  );
};

export default Values;
