import { styled } from "styled-components";
import { theme } from "../../../../theme/theme";

export const Wrapper = styled.div`
  background-color: #eff7f4;
`;

export const Container = styled.div`
  padding: 6rem 4rem;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;

  @media (max-width: 800px) {
    padding: 4rem 1.5rem;
  }
`;

export const GridCon = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 12px;
  margin: 3rem 0;
  @media (max-width: 1150px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 950px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    margin: 2rem 0;
  }
`;

export const GridBoxes = styled.div`
  background-color: #ffffff;
  border-radius: 35px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LinkCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 1.5rem 0 0 0;
  color: ${theme.colors.secondary};
  margin: 1.5rem 0 0 0;
`;
