import React, { useState } from "react";
import { TabsCon, Tabs, Wrapper, TabContentWrapper } from "./Top_styles";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import TabContent from "../tabcontents/TabContent";

const Top = () => {
  const [tabs, setTabs] = useState<{
    weekly?: boolean;
    monthly?: boolean;
  }>({
    weekly: true,
    monthly: false,
  });
  const handleTabClick = (tab: "weekly" | "monthly") => {
    setTabs({ weekly: tab === "weekly", monthly: tab === "monthly" });
  };

  const currentTab = tabs.weekly ? "weekly" : "monthly";

  return (
    <Wrapper>
      <Heading3
        color={`${theme.colors.secondary}`}
        fontSize="16px"
        fontWeight="900"
        fs="12px"
        lh="15px"
        textAlign="center"
      >
        SUBSCRIPTION PLANS
      </Heading3>
      <Heading1
        textAlign="center"
        fontSize="60px"
        fontWeight="900"
        color={`${theme.colors.primary}`}
        lineHeight="90px"
        letterSpacing="0.8px"
        margin="0.5rem 0 16px 0"
        fs="30px"
        lh="37px"
      >
        Upgrade Your Experience
      </Heading1>
      <CommonText
        textAlign="center"
        fontWeight="400"
        fontSize="16px"
        lineHeight="20px"
        color="#282D2B"
        letterSpacing="0.8px"
      >
        Unlock a world of exciting connections with premium features that
        enhance your experience.
      </CommonText>
      <TabsCon>
        <Tabs isActive={tabs.weekly} onClick={() => handleTabClick("weekly")}>
          VIEW WEEKLY PLAN
        </Tabs>
        <Tabs isActive={tabs.monthly} onClick={() => handleTabClick("monthly")}>
          VIEW MONTHLY PLAN
        </Tabs>
      </TabsCon>
      <TabContentWrapper>
      <TabContent currentTab={currentTab} />
      </TabContentWrapper>
    </Wrapper>
  );
};

export default Top;
