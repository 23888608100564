import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const Wrapper = styled.section`
  width: 100%;
  margin: 7rem 0 5rem 0;
  background-color: ${theme.colors.lightBlue};
`;

export const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 8rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6rem;
  @media (max-width: 1150px) {
    gap: 4rem;
  }
  @media (max-width: 991px) {
    gap: 4rem;
    flex-direction: column-reverse;
  }
  @media (max-width: 800px) {
    padding: 4rem 1.5rem;
  }
`;

export const Left = styled.div`
  flex: 1;
  width: 100%;
`;

export const Right = styled.div`
  flex: 1;
`;

export const SmallText = styled.p`
  color: ${theme.colors.secondary};
  font-weight: 900;
  font-size: 16px;
  font-family: '"Kumbh Sans", sans-serif';
  letter-spacing: 0.8px;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const Heading = styled.h1`
  color: ${theme.colors.primary};
  font-weight: 900;
  font-size: 50px;
  font-family: '"Kumbh Sans", sans-serif';
  line-height: 63px;
  letter-spacing: 0.8px;
  margin: 0.5rem 0 1rem;
  @media (max-width: 1150px) {
    font-size: 40px;
    line-height: normal;
  }
  @media (max-width: 991px) {
    font-size: 30px;
    line-height: normal;
  }
  @media (max-width: 800px) {
    font-size: 28px;
    line-height: normal;
    margin: 0.5rem 0 12px;
  }
`;

export const Desc = styled.p`
  color: #282d2b;
  margin-bottom: 1rem;
  font-family: "geist-regular";
  letter-spacing: 0.8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
`;
