import React from "react";
import { Container, Wrapper } from "./Hero_styles";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";

const Hero = () => {
  return (
    <Wrapper>
      <Container>
        <Heading3
          color={`${theme.colors.secondary}`}
          fontWeight="900"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          fs=" 12px"
          lh="15px"
        >
          HELP AND SUPPORT
        </Heading3>
        <Heading1
          color={`${theme.colors.primary}`}
          fontWeight="900"
          fontSize="60px"
          lineHeight="75px"
          letterSpacing="0.8px"
          fs="30px"
          lh="38px"
          margin="0.5rem 0"
        >
          Hello! How Can We Help?
        </Heading1>
        <CommonText
          color={`${theme.colors.primary}`}
          fontWeight="400"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          width="500px"
          w="100%"
        >
          We are here to help with questions about Spowse, requests and
          inquiries.{" "}
        </CommonText>
      </Container>
    </Wrapper>
  );
};

export default Hero;
