import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const FeedBackWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 150px 0;
`;

export const FeedBackContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 1500px;
  flex-direction: column;
  padding: 0 5rem;
  @media (max-width: 800px) {
    padding: 0 1rem;
  }
`;

export const FeedBackContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 3.75rem;
  @media (max-width: 800px) {
    margin-top: 2.5rem;
  }
`;

export const FeedBacksSection = styled.div`
  width: 80%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const FeedBackCard = styled.div`
  width: 100%;
  position: relative;
  background-image: url("/assets/images/review_bg.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 313px;
  padding: 3rem;
  border-radius: 35px;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 800px) {
    background-image: url("/assets/images/review_bg_mobile.png");
    padding: 1.5rem;
  }
`;

export const ArrowContainer = styled.div`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.bgPrimary};
  border-radius: 100%;
  cursor: pointer;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 1.5rem;
`;

export const ArrowContainerMobile = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.bgPrimary};
    border-radius: 100%;
    cursor: pointer;
  }
`;
