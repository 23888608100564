import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 2rem 6rem;

  @media (max-width: 1000px) {
    padding: 2rem 4rem;
  }

  @media (max-width: 800px) {
    padding: 0 1.5rem;
  }
`;

export const Container = styled.div``;

export const ImgCon = styled.div`
  width: 100%;
`;

export const SectionCon = styled.div``