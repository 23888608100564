import styled from "styled-components";

export const Wrapper = styled.section`
 width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 6rem 4rem 0 4rem;
  @media (max-width: 800px) {
    padding: 5.6rem 1.5rem 0 1.5rem;
    max-width: 100%;
  }
`

export const Container= styled.div`
    width: 80%;
    margin: 0 auto;

    @media (max-width: 800px) {
    width: 100%;
  }
`

export const Form = styled.form`
  margin: 3rem 0 0 0;
  width: 100%;
  @media (max-width: 800px) {
    margin: 2rem 0 0 0;
  }
`;

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 46px;
  width: 100%;

  margin-bottom: 40px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 36px;

  }
`;

export const InputCon = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

export const Label = styled.label`
  color: #282d2b;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.8px;
  font-family: "Kumbh Sans", sans-serif;
`;

export const Input = styled.input`
  outline: none;
  border: 1px solid #b5bdba;
  border-radius: 10px;
  height: 50px;
  padding: 8px 15px;
  color: #282d2b;
  font-size: 16px;
  letter-spacing: 0.8px;
  font-weight: 400;
  line-height: 20px;
  font-family: "geist-regular";
`;

export const TextArea = styled.textarea`
  outline: none;
  border: 1px solid #b5bdba;
  border-radius: 10px;
  height: 135px;
  padding: 8px 15px;
  color: #282d2b;
  font-size: 16px;
  letter-spacing: 0.8px;
  font-weight: 400;
  line-height: 20px;
  font-family: "geist-regular";
`;


export const BtnCon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3.8rem;
`