import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 8rem 4rem;
  @media (max-width: 800px) {
    padding: 4.375rem 1.5rem 2.9rem;
}
`;

export const FaqSectionCon = styled.div`
  max-width: 80%;
  margin: 0 auto;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

export const FaqCon = styled.div`
  margin: 3rem 0;
`;

export const Faqq = styled.div``;

export const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const Answer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
`;

export const BtnCon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0;
`;



