import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const Wrapper = styled.section`
    background-color: ${theme.colors.lightBlue};
`

export const Container = styled.div`
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    padding: 6rem 4rem 2rem 4rem;
    
    @media (max-width: 800px) {
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
`


export const BtnCon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 3.75rem;
`

export const ImgCon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
` 