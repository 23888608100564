import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { theme } from "./theme/theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./theme/global_styles";
// import { AppContainer } from "./theme/AppContainer";
import RoutePaths from "./routes/Route";
import ScrollToTopOnNavigate from "./routes/scroll_to_top";

function App() {
  return (
    <Router>
      <ScrollToTopOnNavigate />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {/* <AppContainer> */}
          <RoutePaths />
        {/* </AppContainer> */}
      </ThemeProvider>
    </Router>
  );
}

export default App;
