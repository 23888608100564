import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../theme/theme";

interface TextProbs {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  fontFamily?: any;
  letterSpacing?: any;
  margin?: string;
  textAlign?: string;
  fs?: string;
  fw?: string;
  lh?: string;
  width?: string;
  w?: string;
  wid?: string;
  m?: string;
  contentWid?: string;
  contentSize?: string;
}

export const Heading1 = styled.h1<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "2rem"};
  font-weight: ${(props) => props.fontWeight || "900"};
  font-family: ${(probs: any) => probs.fontFamily || '"Kumbh Sans", sans-serif'};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
  margin: ${(props) => props.margin || " 0"};
  text-align: ${(props) => props.textAlign || "left"};
  width: ${(props) => props.width};

  @media (max-width: 1400px) {
    width: ${(props) => props.wid};

  }
  @media (max-width: 991px) {
    width: ${(props) => props.contentSize};

  }

  @media (max-width: 800px) {
    font-size: ${(props) => props.fs || "20"};
    line-height: ${(props) => props.lh};
    width: ${(props) => props.w};

  }
`;

export const Heading2 = styled.h2<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "1.5rem"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  font-family: ${(probs: any) => probs.fontFamily || '"Kumbh Sans", sans-serif'};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
  @media (max-width: 800px) {
    font-size: ${(props) => props.fs };
    line-height: ${(props) => props.lh};
  }
`;

export const Heading3 = styled.h3<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "1.25rem"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  font-family: ${(probs: any) => probs.fontFamily || '"Kumbh Sans", sans-serif'};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
  text-align: ${(props) => props.textAlign || "left"};
  margin: ${(props) => props.margin || "0"};
  line-height: ${(props) => props.lineHeight || "25px"};

  
  @media (max-width: 800px) {
    font-size: ${(props) => props.fs };
    /* font-weight: ${(props) => props.fw || "bold"}; */
    line-height: ${(props) => props.lh};
  }
`;

export const Heading4 = styled.h4<TextProbs>`
  color: ${(props) => props.color || "#10281E"};
  font-size: ${(props) => props.fontSize || "24px"};
  font-weight: ${(props) => props.fontWeight || "900"};
  font-family: ${(probs: any) => probs.fontFamily || '"Kumbh Sans", sans-serif'};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.8px"};
  text-align: ${(props) => props.textAlign || "left"};
  margin: ${(props) => props.margin || "0"};
  line-height: ${(props) => props.lineHeight || "30px"};

  @media (max-width: 800px) {
    font-size: ${(props) => props.fs || "20px"};
    line-height: ${(props) => props.lh || "25px"};
    margin: ${(props) => props.m };
  }
`;

export const CommonText = styled.p<TextProbs>`
  color: ${(props) => props.color || "#282D2B"};
  font-size: ${(props) => props.fontSize || "18px"};
  font-weight: ${(props) => props.fontWeight || "400"};
  line-height: ${(props) => props.lineHeight || "22px"};
  font-family: ${(probs: any) => probs.fontFamily || "geist-regular"};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.8px"};
  text-align: ${(props) => props.textAlign || "left"};
  margin: ${(props) => props.margin || "0"};
  width: ${(props) => props.width};


  @media (max-width: 1024px) {
    width: ${(props) => props.wid};

  }

  @media (max-width: 800px) {
    font-size: ${(props) => props.fs || "16px"};
    line-height: ${(props) => props.lh || "20px"};
    width: ${(props) => props.w};
    margin: ${(props) => props.m };

  }
  @media (max-width: 600px) {
    width: ${(props) => props.contentWid};

  }
`;

export const LinkText = styled(Link)<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "regular"};
  line-height: ${(props) => props.lineHeight};
  font-family: ${(probs: any) => probs.fontFamily || "geist-regular"};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.lightGreen};
    transition: all 300ms ease-in;
  }
`;

export const SpanText = styled.span<TextProbs>`
  color: ${(props) => props.color || "#10281E"};
  font-size: ${(props) => props.fontSize || "inherit"};
  font-weight: ${(props) => props.fontWeight || "700"};
  font-family: ${(probs: any) => probs.fontFamily || "geist-regular"};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.8px"};
  line-height: ${(props) => props.lineHeight || "inherit"};


  @media (max-width: 800px) {
    font-size: ${(props) => props.fs || "inherit"};
    line-height: ${(props) => props.lh || "inherit"};
    width: ${(props) => props.w};
  }
`;

export const Heading6 = styled.h6<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "0.875rem"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  font-family: ${(probs: any) => probs.fontFamily || "geist-regular"};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
`;
