import {
  BtnCon,
  Container,
  FileBtnDiv,
  FileInput,
  FileLabel,
  Form,
  Input,
  InputCon,
  InputRow,
  Label,
  TextArea,
  Wrapper,
} from "./form_styles";
import {
  CommonText,
  Heading1,
  Heading3,
  SpanText,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { theme } from "../../../../theme/theme";
import ApiService from "../../../../core/api/api";
import { useRef, useState } from "react";
import { ApiUrl } from "../../../../core/api/api_url";

const RequestForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    workExperience: "",
    resume: null as File | null,
  });
  const [resumeUrl, setResumeUrl] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [fileName, setFileName] = useState<string | null | any>(null);
  const [message, setMessage] = useState("");


  const fileInputRef = useRef<HTMLInputElement | null>(null);


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUploading(true);
      setFileName(file.name);

      // Convert file to base64
      const base64String = await toBase64(file);
 
      // Upload to Pixelslash and get the URL
      const apiService = new ApiService();
      const response: any = await apiService.postData(ApiUrl.PIXEL_SLASH, {
        fileType: "document",
        fileContent: base64String,
      });

      if (response.success && response.data) {
        setResumeUrl(response.data); // set the URL returned by Pixelslash
      }

      setUploading(false);
    }
  };
       
  const toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1]; // Remove prefix
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!resumeUrl) return; // Ensure resume upload is complete
    setMessage("")

    const jobApplicationData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      resume: resumeUrl,
      workExperience: formData.workExperience,
    };

    const apiService = new ApiService();
    const response = await apiService.postData(ApiUrl.JOBS, jobApplicationData);

    if (response.success) {
      setMessage("Application submitted successfully!");
      setFormData(  {
        firstName: "", 
        lastName: "",
        email: "",
        resume: null,
        workExperience: "",
      });
      setFileName(null);
      setResumeUrl(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; 
      }
    } else {
      setMessage("Failed to submit application. Please try again.");
    }
  };

  return (
    <Wrapper>
      <Container>
        <Heading3
          fontSize="16px"
          fontWeight="900"
          lineHeight="20px"
          letterSpacing="0.8px"
          fs="12px"
          lh="15px"
          color={`${theme.colors.secondary}`}
        >
          WELCOME
        </Heading3>
        <Heading1
          fontSize="60px"
          fontWeight="900"
          lineHeight="75px"
          letterSpacing="0.8px"
          fs="30px"
          lh="38px"
          color={`${theme.colors.primary}`}
          margin="0.8rem 0"
        >
          Submit Your Application
        </Heading1>
        <Form onSubmit={handleSubmit}>
          <InputRow>
            <InputCon>
              <Label>First name</Label>
              <Input
                type="text"
                required
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </InputCon>
            <InputCon>
              <Label>Last name</Label>
              <Input
                type="text"
                required
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </InputCon>
          </InputRow>
          <InputRow>
            <InputCon>
              <Label>Your email address</Label>
              <Input
                placeholder="Daisyohikhuare20@gmail.com"
                type="email"
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </InputCon>
          </InputRow>
          <InputRow>
            <InputCon>
              <Label>Upload Resume</Label>
              <FileInput>
                <FileLabel>
                  <Input
                    type="file"
                    style={{ display: "none" }}
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                  />
                  <FileBtnDiv>
                    {uploading ? (
                      <CommonText>Uploading...</CommonText>
                    ) : (
                      fileName || (
                        <>
                          <CommonText
                            fontSize="16px"
                            letterSpacing="0.8px"
                            lineHeight="20px"
                            color="#282D2B"
                          >
                            <SpanText
                              fontWeight="inherit"
                              color={`${theme.colors.secondary}`}
                              letterSpacing="0.8px"
                              lineHeight="20px"
                              fontSize="inherit"
                            >
                              Choose file
                            </SpanText>{" "}
                            or drag here (max 2MB)
                          </CommonText>
                        </>
                      )
                    )}
                  </FileBtnDiv>
                </FileLabel>
              </FileInput>
            </InputCon>
          </InputRow>
          <InputRow>
            <InputCon>
              <Label>Describe your work experience</Label>
              <TextArea
                required
                name="workExperience"
                value={formData.workExperience}
                onChange={handleChange}
              />
            </InputCon>
          </InputRow>
          <CommonText
            fontSize="14px"
            fontWeight="400"
            lineHeight="17px"
            letterSpacing="0.8px"
            color="#5C6360"
            margin="-15px 0 3rem 0"
          >
            Please enter the details of your work experience, providing as much
            information as possible
          </CommonText>
          <BtnCon>
            <SpowseButton
              type="submit"
              width="178px"
              disabled={uploading || !resumeUrl}
            >
              Submit
            </SpowseButton>
          </BtnCon>
          {message && (
            <CommonText
              fontSize="14px"
              fontWeight="400"
              lineHeight="17px"
              letterSpacing="0.8px"
              color={message.startsWith("Error") ? "red" : "green"}
            >
              {message}
            </CommonText>
          )}
        </Form>
      </Container>
    </Wrapper>
  );
};

export default RequestForm;
