import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import Hero from './components/hero/Hero'
import StoryForm from './components/story-form/StoryForm'
import { Helmet } from 'react-helmet-async'

const SuccessStory = () => {
  return (
    <div>
      <Helmet>
        <title>Success Story - Spowse</title>
        <meta
          name="description"
          content="tell us your success story"
        />

        <meta charSet="utf-8" />
        <link rel="canonical" href={`/success-story`} />
      </Helmet>
     <Header />
     <Hero />
     <StoryForm />
     <Footer />
    </div>
  )
}

export default SuccessStory
