import styled from "styled-components";

export const Wrapper =  styled.section`

`

export const Container = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    padding: 6rem 10rem;

    @media (max-width: 1024px) {
    padding: 4rem 6rem;
  }

  @media (max-width: 800px) {
    padding: 3rem 1.5rem 0 1.5rem;
  }
`

export const List = styled.div``

export const Li = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 2rem 0 1rem 1rem;
    @media (max-width: 800px) {
        margin: 1rem 0 1rem 1rem;
    }
`

export const Icon = styled.span`
    font-size: 16px;
`