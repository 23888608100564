import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const Wrapper = styled.section`
  background-color: ${theme.colors.bgPrimary};
  margin: 8rem 0 6rem 0;

  @media (max-width: 800px) {
    margin: 4.4rem 0 6.25rem;
}
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 6rem 4rem;

  @media (max-width: 800px) {
    padding: 4rem 1.5rem;
}
`;

export const FlexCon = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 90px;

    @media (max-width: 1100px) {
        gap:50px;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 40px;
}
`

export const Left = styled.div`
    flex: 1;
`;

export const Right = styled.div`
    flex: 1;
`;

export const BtnCon = styled.div`
  margin-top: 2rem;
`;
