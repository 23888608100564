import React from 'react'
import Header from '../header/Header'
import Hero from './component/Hero/Hero'
import Faq from './component/Faq/Faq'
import Cta from './component/Cta/Cta'
import Footer from '../footer/Footer'
import { Helmet } from 'react-helmet-async'

const Help = () => {
  return (
    <div>
      <Helmet>
        <title>Help and Support - Spowse</title>
        <meta
          name="description"
          content="Have questions or need support? Contact the Spowse team for assistance with your dating experience. We're here to help with any inquiries or feedback. Reach out to us for quick and friendly support, and let us guide you on your journey to finding meaningful connections."
        />

        <meta charSet="utf-8" />
        <link rel="canonical" href={`/contact-us/help-and-support`} />
      </Helmet>
      <Header />
      <Hero />
      <Faq />
      <Cta />
      <Footer />
    </div>
  )
}

export default Help
