import React, { useState } from "react";
import {
  BtnCon,
  Container,
  Form,
  Input,
  InputCon,
  InputRow,
  Label,
  TextArea,
  Wrapper,
} from "./Form_styles";
import {
  CommonText,
  Heading1,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { theme } from "../../../../theme/theme";
import SuccessStoryService from "../../../../core/api/services/success_story";

const StoryForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    partnerDescription: "",
    story: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setMessage(""); // Reset submission message

    try {
      const response = await SuccessStoryService.submitSuccessStoryForm(
        formData
      );
      if (response.success) {
        setMessage("Success Story submitted successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          partnerDescription: "",
          story: "",
        });
      } else {
        setMessage(`Failed to submit: ${response.message}`);
      }
    } catch (error) {
      setMessage("Error submitting the form.");
      console.error("Submission error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Heading1
          color={`${theme.colors.primary}`}
          fontWeight="900"
          fontSize="50px"
          lineHeight="63px"
          letterSpacing="0.8px"
          fs="28px"
          lh="35px"
          margin="0.7rem 0"
          textAlign="center"
        >
          Tell your story
        </Heading1>
        <CommonText
          color="#282D2B"
          fontWeight="400"
          fontSize="16px"
          lineHeight="20px"
          letterSpacing="0.8px"
          textAlign="center"
          width="600px"
          w="100%"
          margin="16px auto 36px"
        >
          Fill in the details requested below and share your story. We are
          looking forward to it.
        </CommonText>
        <Form onSubmit={handleSubmit}>
          <InputRow>
            <InputCon>
              <Label>First name</Label>
              <Input
                type="text"
                required
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </InputCon>
            <InputCon>
              <Label>Last name</Label>
              <Input
                type="text"
                required
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </InputCon>
          </InputRow>
          <InputRow>
            <InputCon>
              <Label>What is your email address?</Label>
              <Input
                placeholder="Daisyohikhuare20@gmail.com"
                type="email"
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </InputCon>
          </InputRow>
          <InputRow>
            <InputCon>
              <Label>Kindly describe your partner</Label>
              <TextArea
                required
                name="partnerDescription"
                value={formData.partnerDescription}
                onChange={handleChange}
              />
            </InputCon>
          </InputRow>
          <InputRow>
            <InputCon>
              <Label>What's your succes story?</Label>
              <TextArea
                required
                name="story"
                value={formData.story}
                onChange={handleChange}
              />
            </InputCon>
          </InputRow>
          <BtnCon>
            <SpowseButton type="submit" width="178px">
              {loading ? "Submitting..." : "Submit"}
            </SpowseButton>
          </BtnCon>
          {message && (
            <CommonText
              fontSize="14px"
              fontWeight="400"
              lineHeight="17px"
              letterSpacing="0.8px"
              color={message.startsWith("Error") ? "red" : "green"}
            >
              {message}
            </CommonText>
          )}
        </Form>
      </Container>
    </Wrapper>
  );
};

export default StoryForm;
