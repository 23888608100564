export const slider = [
  {
    url: "https://spowse-resized-images.s3.amazonaws.com/9046aab3-1cad-4092-b67a-55c9cfe90baa-trybe_1.png",
    category: "PRODUCT UPDATE",
    title: "An Introduction To Online Dating For Africans",
    date: "26 May 2023",
    urlMobile: "https://spowse-resized-images.s3.amazonaws.com/d509c888-99dd-42c4-b12a-715865d76f78-trybe_mobile%21.png"
  },
  {
    url: "https://spowse-resized-images.s3.amazonaws.com/75e5e38e-5c28-4f62-bbfe-5ecfaafd175c-trybe_2.png",
    category: "DATING TIPS",
    title: "An Introduction To Online Dating For Africans",
    date: "26 October 2024",
    urlMobile: "https://spowse-resized-images.s3.amazonaws.com/e0ab3828-b30d-4a9b-bf1a-ee773f6ddeee-trybe_mobile2.png"
  },
  {
    url: "https://spowse-resized-images.s3.amazonaws.com/cbef6a12-95fa-4a92-a8bc-d380193d35cb-trybe_3.png",
    category: "HOW-TOS",
    title: "Stand Out In The Dating World With These Few Tips",
    date: "26 May 2023",
    urlMobile: "https://spowse-resized-images.s3.amazonaws.com/69076dd1-6357-4011-9b5e-b48cd80073ce-trybe_mobile2.png"
  },
];
