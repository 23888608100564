import React from "react";
import Header from "../../../header/Header";
import { HeroSection, Left, Right } from "./Hero_styles";
import {
  CommonText,
  Heading1,
  Heading3,
} from "../../../../core/common/reuseables/text_styles";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../../core/utils/app_images";
import { theme } from "../../../../theme/theme";

const Hero = () => {
  return (
    <main>
      <Header />
      <HeroSection>
        <Left>
          <Heading3
            fontSize="16px"
            fontWeight="900"
            lineHeight="20px"
            color={`${theme.colors.secondary}`}
            letterSpacing="0.8px"
            fs="12px"
            lh="15px"
          >
            DO YOU NEED HELP?
          </Heading3>
          <Heading1
            fontSize="60px"
            lineHeight="75px"
            fontWeight="900"
            letterSpacing="0.8px"
            color={`${theme.colors.primary}`}
            margin="6px 0 10px 0"
            fs="30px"
            lh="38px"
          >
            Let’s Connect! We’ve Got You Covered
          </Heading1>
          <CommonText
            fontSize="16px"
            fontWeight="400"
            lineHeight="20px"
            color="#282D2B"
            letterSpacing="0.8px"
          >
            Browse our FAQS or reach out to our friendly customer support team.
          </CommonText>
        </Left>
        <Right>
          <SpowseImage src={AppImages.contactHero} alt="img" />
        </Right>
      </HeroSection>
    </main>
  );
};

export default Hero;
