import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Faq from "./components/frequent-asked-questions/Faq";
import { Helmet } from "react-helmet-async";

const Faqs = () => {
  return (
    <div>
      <Helmet>
        <title> Faqs - Spowse</title>
        <meta
          name="description"
          content="Find answers to common questions about using Spowse. Our FAQs provide helpful information on creating profiles, finding matches, managing your account, and more. Get the guidance you need to make the most of your Spowse experience."
        />
        <meta charSet="utf-8" />
        <link rel="canonical" href={`/faqs`} />
      </Helmet>
      <Header />
      <Faq />
      <Footer />
    </div>
  );
};

export default Faqs;
