import React from "react";
import { HomeWrapper } from "./home_styles";
import Hero from "./widgets/hero_section/Hero";
import ExploreSection from "./widgets/explore_section/ExploreSection";
import DiscoverLove from "./widgets/discover_love/DiscoverLove";
import WhySpowse from "./widgets/why_spowse/WhySpowse";
import FeedBack from "./widgets/feed_back_section/FeedBack";
import TrybeSection from "./widgets/trybe_section/TrybeSection";
import Footer from "../footer/Footer";
import Header from "./home_header/Header";
import { Helmet } from "react-helmet-async";

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  return (
    <HomeWrapper>
      <Helmet>
        <title>Spowse</title>
        <meta
          name="description"
          content="Spowse is a dynamic dating platform that helps you connect with like-minded individuals. Discover meaningful relationships, engage in real conversations, and explore profiles in a safe and welcoming environment. Whether you're searching for love or friendship, Spowse is here to enrich your dating journey with authenticity and trust."
        />
        <meta charSet="utf-8" />
        <link rel="canonical" href={`/`} />
      </Helmet>
      <Header />
      <Hero />
      <ExploreSection />
      <DiscoverLove />
      <WhySpowse />
      <FeedBack />
      <TrybeSection />
      <Footer />
    </HomeWrapper>
  );
};
export default Home;
