import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 4rem 4rem 0 4rem;

  @media (max-width: 800px) {
    padding:3rem 1.5rem 0 1.5rem;
  }
`;

export const Container = styled.div``;

export const GridCon = styled.div`
  margin: 3rem 0 4rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    margin: 1.5rem 0 2rem 0;
  }
`;

export const GridItems = styled.div``;

export const ImgCon = styled.div`
  width: 100%;
`;

export const FormSection = styled.section`
  background-image: url(/assets/images/formbg.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 35px;
  padding: 4rem 6rem;
  margin: 6rem 0 0 0;
  @media (max-width: 991px) {
    padding: 3rem 2rem;
  }
    @media (max-width: 800px) {
    margin: 3rem 0 0 0;
  }
`

export const FormContainer = styled.div`
  background-color:rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0.5rem;
  gap: 12px;
`

export const Form = styled.form``

export const InputCon = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;
  border-radius: 50px ;
  padding: 7px 10px;
  gap: 20px;
  width: 580px;
  @media (max-width: 768px) {
    width: 450px;
  }
  @media (max-width: 600px) {
    width: 315px;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`

export const Input = styled.input`
  border: none;
  outline: none;
  height: 50px;
  margin-left: 10px;
  font-size: 18px;
  width: 60%;
  line-height: 22px;
  @media (max-width: 768px) {
    width: 85%;
    font-size: 14px;
    line-height: 18px;
  }

`

export const BtnConBig = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

export const BtnCon = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`



