import React from "react";
import {
  Container,
  Desc,
  Heading,
  LogoGrid,
  LogoItem,
  SmallText,
  Wrapper,
} from "./Partners_styles";
import { partnerLogos } from "../../data/data";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";

const Partners = () => {
  return (
    <Wrapper>
      <Container>
        <SmallText>BUILDING {`AFRICA’S`} LOVE NETWORK</SmallText>
        <Heading>Meet Our Partners At Spowse</Heading>
        <Desc>
          We are backed by incredible partners who believe in our vision and
          goal for the African community.
        </Desc>
        <LogoGrid>
          {partnerLogos.map((logo, index) => (
            <LogoItem key={index}>
              <SpowseImage src={logo.image} alt="img" w="100px" />
            </LogoItem>
          ))}
        </LogoGrid>
      </Container>
    </Wrapper>
  );
};

export default Partners;
