import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  FadeInTextContainer,
  ImageSection,
  HeroWrapper,
  HeroContent,
  MiddleContext,
  PaginationContainer,
  ActiveContainner,
} from "./Hero_styles";
import {
  CommonText,
  Heading1,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { Link } from "react-router-dom";

const Hero: React.FC = () => {
  const desktopBgImages = [
    "https://spowse-resized-images.s3.amazonaws.com/235e78ec-52ac-4367-b745-a4810d3faf39-home_hero1",
    "https://spowse-resized-images.s3.amazonaws.com/2f386d52-bad1-4d61-8df0-a71d78cfc960-home_hero2",
    "https://spowse-resized-images.s3.amazonaws.com/384c2ff2-a8eb-423f-a137-48960f5a8d9c-home_hero3",
  ];

  const mobileBgImages = [
    "https://spowse-resized-images.s3.amazonaws.com/0f9cea40-a289-4688-8a3b-29fb7a4eb3f5-home_mobilehero1",
    "https://spowse-resized-images.s3.amazonaws.com/47fe267c-0676-4dca-a232-7907aa9a4dc2-home_mobilehero2",
    "https://spowse-resized-images.s3.amazonaws.com/f3394c52-9e99-42ca-9e28-f37e16b7a6f0-home_mobilehero3",
  ];

  const tabImgs = [
    "https://spowse-resized-images.s3.amazonaws.com/64ad07bb-8432-4edc-a2b5-28d591880dba-home_tablethero1",
    "https://spowse-resized-images.s3.amazonaws.com/a062d5e7-00bc-4585-80bc-ecf3b5f2d87e-home_tablethero1",
    "https://spowse-resized-images.s3.amazonaws.com/90812e5d-2fa7-43de-91bb-a53372017ea5-home_tablethero3",
  ];

  const texts = [
    "The App Where Every African Heart Finds Home",
    "More Than Slides, Find Your Ideal Partner",
    "Your Forever Starts Here. Love, Spowse",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % desktopBgImages.length);
    }, 6000);

    return () => clearInterval(intervalId);
  }, [desktopBgImages.length]);

  return (
    <HeroWrapper>
      <HeroContent>
        <AnimatePresence>
          <ImageSection
            key={currentIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 1.3,
              ease: "easeInOut",
            }}
            backgroundImage={`url(${desktopBgImages[currentIndex]})`}
            bgImage={`url(${mobileBgImages[currentIndex]})`}
            tabletImg={`url(${tabImgs[currentIndex]})`}
          />
        </AnimatePresence>
        {/* fade text section */}

        <FadeInTextContainer>
          <MiddleContext>
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, y: 80 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -80 }}
              transition={{
                duration: 1.3,
                ease: "easeInOut",
              }}
            >
              <Heading1
                fontSize="70px"
                fontFamily='"Kumbh Sans", sans-serif'
                fontWeight="900"
                letterSpacing="0.8px"
                lineHeight="88px"
                color={`${theme.colors.white}`}
                textAlign="center"
                fs="35px"
                lh="44px"
                width="1000px"
                contentSize="100%"
                w="100%"
              >
                {texts[currentIndex]}
              </Heading1>
            </motion.div>
            <CommonText
              fontSize="18px"
              fs="16px"
              fontFamily="geist-regular"
              fontWeight="400"
              color="rgba(242, 242, 242, 1)"
              letterSpacing="0.2px"
              textAlign="center"
              width="70%"
              w="100%"
              margin="3px 0 26px 0"
            >
              Forge meaningful connections and lasting relationships in a
              community of African singles who value commitment like you do.
            </CommonText>

            <Link
              to="https://play.google.com/store/apps/details?id=io.spowse.androidApp"
              style={{ textDecoration: "none", color: "inherit" }}
              target="_blank"
            >
              <SpowseButton
                width="198px"
                height="52px"
                backgroundColor="transparent"
                bg="#ffffff"
                color="#10281E"
              >
                Download The App
              </SpowseButton>
            </Link>
          </MiddleContext>
        </FadeInTextContainer>
        <PaginationContainer>
          {texts.map((e, index) => {
            return (
              <ActiveContainner
                key={e}
                activeIndex={currentIndex}
                index={index}
              ></ActiveContainner>
            );
          })}
        </PaginationContainer>
      </HeroContent>
    </HeroWrapper>
  );
};

export default Hero;
