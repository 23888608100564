import React from "react";
import Hero from "./components/hero/Hero";
import Cta from "./components/cta/Cta";
import Third from "./components/third/Third"
import Footer from "../footer/Footer";
import Mission from "./components/mission/Mission";
import Partners from "./components/partners/Partners";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <main>
      <Helmet>
        <title>About - Spowse</title>
        <meta
          name="description"
          content="Spowse is a dynamic dating platform that helps you connect with like-minded individuals. Discover meaningful relationships, engage in real conversations, and explore profiles in a safe and welcoming environment. Whether you're searching for love or friendship, Spowse is here to enrich your dating journey with authenticity and trust."
        />
        <meta charSet="utf-8" />
        <link rel="canonical" href={`/about`} />
      </Helmet>
      <Hero />
      <Cta />
      <Third />
      <Mission />
      <Partners />
      <Footer />
    </main>
  );
};

export default About;
