import styled from "styled-components";

interface SpowseImageProbs {
  height?: string;
  width?: string;
  borderRadius?: string;
  w?: string;
  display?: string;
  dis?: string;
  m?: string;
  h?: string;
  margin?: string;

}

export const SpowseImage = styled.img<SpowseImageProbs>`
  width: ${(probs) => probs.width || "100%"};
  height: ${(probs) => probs.height};
  border-radius: ${(probs) => probs.borderRadius};
  display: ${(probs) => probs.display};
  margin: ${(props) => props.margin};


  @media (max-width: 800px) {
    width: ${(probs) => probs.w};
    display: ${(probs) => probs.dis};
    margin: ${(props) => props.m};
    height: ${(probs) => probs.h};

  }
`;
