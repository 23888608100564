import React, { useState } from "react";
import {
  CustomLink,
  HeaderContent,
  HeaderContentLeftSection,
  HeaderContentRightSection,
  HeaderWrapper,
} from "./header_styles";
import { AppIcons } from "../../../core/utils/app_icons";
import { useMatch, useResolvedPath } from "react-router-dom";
import { SpowseImage } from "../../../core/common/reuseables/spowse_image";
import { IconCon } from "../../header/styles";
import Sidebar from "../../sidebar/Sidebar";

type HeaderProps = {};

const Header: React.FC<HeaderProps> = () => {
  const [sidebar, setSideBar] = useState(false);

  const showSidebar = () => {
    setSideBar(!sidebar);
  };

  return (
    <>
      <HeaderWrapper backgroundColor={"transparent"}>
        <HeaderContent>
          <HeaderContentLeftSection>
            <a href="/">
              <SpowseImage src={AppIcons.logoHome} alt="img" />
            </a>
          </HeaderContentLeftSection>
          <HeaderContentRightSection>
            <CustomLinks onTap={() => {}} to="/product">
              Product
            </CustomLinks>
            <CustomLinks onTap={() => {}} to="/about">
              About
            </CustomLinks>
            <CustomLinks onTap={() => {}} to="/safety">
              Safety
            </CustomLinks>
            <CustomLinks onTap={() => {}} to="/trybe">
              Trybe
            </CustomLinks>
            <CustomLinks onTap={() => {}} to="/contact-us">
              Contact Us
            </CustomLinks>
          </HeaderContentRightSection>
          <IconCon onClick={showSidebar}>
            <SpowseImage
              src={AppIcons.hamburgerHome}
              display="none"
              dis="block"
              width="25px"
            />
          </IconCon>
        </HeaderContent>
      </HeaderWrapper>
      {<Sidebar active={setSideBar} sidebar={sidebar} />}
    </>
  );
};
export default Header;

interface CustomLinksProps {
  to: string;
  children: React.ReactNode;
  onTap?: any;
  scrolling?: any;
}

function CustomLinks({ to, children, onTap, scrolling }: CustomLinksProps) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <CustomLink to={to} active={isActive} onClick={onTap}>
      {children}
    </CustomLink>
  );
}
