import React from 'react'
import Hero from './components/hero/Hero'
import Life from './components/life/Life'
import Footer from '../footer/Footer'
import Culture from './components/culture/Culture'
import FeedBack from './components/feed_back_section/FeedBack'
import Cta from './components/cta/Cta'
import { Helmet } from 'react-helmet-async'
import Values from './components/values/Values'

const Career = () => {
  return (
    <div>
      <Helmet>
        <title>Career - Spowse</title>
        <meta
          name="description"
          content="Join the team at Spowse and help shape the future of dating. Explore career opportunities in a dynamic and innovative environment. Whether you're a developer, designer, or marketing expert, we're always looking for talented individuals to join us in building meaningful connections."
        />

        <meta charSet="utf-8" />
        <link rel="canonical" href={`/career`} />
      </Helmet>
      <Hero />
      <Life />
      <Culture />
      {/* <FeedBack /> */}
      <Values />
      <Cta />
      <Footer />
    </div>
  )
}

export default Career
