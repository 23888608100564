import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  background-image: url("/assets/images/explore_bgg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 8rem 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    padding: 4rem 1.5rem;
  }
`;
