// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show {
  height: auto;
  max-height: 999px;
  /* transition: all 0.5s cubic-bezier(1, 0, 1, 0); */
}
.hide{
  max-height: 0;
  overflow: hidden;
  /* transition: all 0.5s cubic-bezier(0, 1, 0, 1); */
}

.icon {
  font-size: 24px;
}

@media (max-width: 800px) {
  .icon {
    font-size: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/presentations/help-and-support/help.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,mDAAmD;AACrD;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,mDAAmD;AACrD;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".show {\n  height: auto;\n  max-height: 999px;\n  /* transition: all 0.5s cubic-bezier(1, 0, 1, 0); */\n}\n.hide{\n  max-height: 0;\n  overflow: hidden;\n  /* transition: all 0.5s cubic-bezier(0, 1, 0, 1); */\n}\n\n.icon {\n  font-size: 24px;\n}\n\n@media (max-width: 800px) {\n  .icon {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
