import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Hero from "./components/hero/Hero";
import Features from "./components/features/Features";
import Secuirity from "./components/secuirity/Secuirity";

const Safety = () => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <Hero />
      <Features />
      <Secuirity />
      <Footer />
    </div>
  );
};

export default Safety;
