export const faqs = [
  {
    question: "What is Spowse?",
    ans1: "Spowse is a dating platform designed to connect Africans looking for meaningful and long term relationships. Our mission is to make it easier for you to find meaningful relationships and life partners by bridging the gap between love and technology.",
    ans2: "Spowse is tailored to fit the diverse needs of Africans, with features that make connecting safe, easy and fun. ",
    ans3: "",
    ans4: "",
  },
  {
    question: "How does Spowse work?",
     ans1: "Getting started on Spowse is easy. To get started, create your profile using your phone number, Apple ID, or Gmail. You’ll answer a few important questions, and your profile will be ready to go.",
    ans2: "Once your profile is set up, explore potential matches using our sliding feature on the “For You” and “Explore” screens, tailored to your preferences for nationality, ethnicity, and interests.",
    ans3: "Connect with others by liking profiles, saving profiles, sending instant messages, or using our Cupid Time feature to find users who are in the same setting as you. if you match, you have 24 hours to start a video call and secure the connection.",
    ans4: "Spowse gives you control with options like incognito mode, profile boosts, and selective connections, making it a safe, simple and fun way to find genuine connections.",
  },
  {
    question: "What makes Spowse different from other platforms ?",
     ans1: "Spowse is different because it’s built with a deep understanding of African dating culture. Our platform includes features like local language options, culturally relevant content, and a sliding feature similar to popular social media interactions.",
    ans2: "We prioritize safety and respect, and we don’t use location tracking to display profiles. instead we use nationality and state of residence options to show profiles and we offer unique features like Cupid Time, which connects users based on shared locations without revealing exact details.",
    ans3: "Our goal is to provide a safe, familiar and welcoming space for Africans to find love.",
    ans4: "",
  },
  {
    question: "Is Spowse safe and secure?",
     ans1: "Yes, safety is at the core of everything we do at Spowse. We use advanced security measures to protect your data and ensure a safe environment for all users.",
    ans2: "Our features are designed to encourage respectful interactions, and we offer options to block or report any suspicious activity. We also provide a 24-hour video call time feature to help verify connections and build trust. Our team is always here to support you, so you can enjoy your experience with peace of mind.",
    ans3: "",
    ans4: "",
  },
  {
    question: "How can I learn more about Spowse ? ",
     ans1: 'To learn more about Spowse, visit our website sections like "About Us",  "Safety", and "FAQs". You can also follow us on social media for updates, tips, and community stories. If you have specific questions, feel free to reach out to us via email at hello@spowse.io or submit a request through our "Contact Us" page.',
    ans2: "",
    ans3: "",
    ans4: "",
  },
  {
    question: "Can I use Spowse for free?",
     ans1: "Yes, Spowse offers a free version with essential features to help you start connecting. As a first time user, you’re entitled to a 7 day free trial.  For an enhanced experience, you can upgrade to one of our premium plans, which provide additional features like more daily likes, unlimited messaging, and profile boosts.",
    ans2: "",
    ans3: "",
    ans4: "",
  },
  {
    question: "How do I delete my Spowse account?",
     ans1: 'If you wish to delete your Spowse account, go to your profile settings and select "Delete Account." Follow the prompts to confirm. Remember, deleting your account is permanent and all your data will be removed from our platform.',
    ans2: "",
    ans3: "",
    ans4: "",
  },
  {
    question: "What should I do if i encounter a problem on Spowse?",
     ans1: 'If you experience any issues while using Spowse, you can visit our "Help and Support" section for troubleshooting tips. You can also contact our support team directly via email at hello@spowse.io or through the "Contact Us" page on our website.',
    ans2: "",
    ans3: "",
    ans4: "",
  },
  {
    question: "What cool features does Spowse have?",
     ans1: "Spowse is full of fun features to help you find the right match:",
    ans2: "Sliding Feature: Forget swiping left or right! Our sliding feature makes browsing profiles feel more like scrolling through social media.",
    ans3: "Cupid Time: Get an alert when another Spowse user is nearby, so you can see if there’s a potential match close to you.",
    ans4: "Incognito Mode: Want to keep things low-key? Hide your profile from everyone except the people you've liked or connected with.",
    ans5: "Selective Connections: Limit the number of profiles you interact with daily to focus on more meaningful connections."
  },
  {
    question: "What’s the difference between boost, premium and platinum?",
     ans1: "Spowse offers three levels of membership to suit your needs:",
    ans2: "Boost: Gives your profile more visibility for 8 hours so more people see you and profile backtracking amongst others.",
    ans3: "Premium: Extends your visibility to 12 hours, gives you more daily likes, unlimited messaging, and the option to snooze your profile.",
    ans4: "Platinum: Offers the most perks, like 24-hour profile boosts, even more daily likes, extended video call times, VIP profile access, and Incognito Mode.",
  },
  {
    question: "What are supernova likes and how do they work?",
     ans1: "Supernova Likes are a special type of like that always appears at the top of someone’s list. They come with a cool animation, making sure your profile stands out and gets noticed! Tap and hold the love icon on a profile to send a supernova like.",
    ans2: "",
    ans3: "",
    ans4: "",
  },
  {
    question: "Can I control who sees my profile?",
     ans1: "Absolutely! With Incognito Mode, you can hide your profile from everyone except those you’ve liked or matched with. You can also manage who contacts you by using our block and report features for any unwanted interactions.",
    ans2: "",
    ans3: "",
    ans4: "",
  },
  {
    question: "What happens if I don’t make a video call with my match within 24 hours?",
     ans1: "If you don’t start a video call within 24 hours after matching, the connection will expire. However, if you have a Premium or Platinum membership, you can extend the time by an additional 2, 4, or 6 hours to keep the match active.",
    ans2: "",
    ans3: "",
    ans4: "",
  },
  {
    question: "What if i slide past a profile by mistake?",
     ans1: "No worries! You can use the Backtracking feature to go back and take another look at the profile you accidentally slid past. It’s a great way to make sure you don’t miss any potential matches.",
    ans2: "",
    ans3: "",
    ans4: "",
  },
  {
    question: "How does Spowse keep my information secure?",
     ans1: "Your privacy and security are our top priorities. Spowse uses advanced encryption to protect your personal data and only displays the information you choose to share. We also have a facial verification process to authenticate profiles and ensure a safe environment.",
    ans2: "",
    ans3: "",
    ans4: "",
  },
];
