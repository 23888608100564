import styled from "styled-components";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  align-items: center;
  max-width: 1120px;
  margin: 0 auto;
  gap: 30px;

  @media (max-width: 1180px) {
    grid-template-columns: 1fr 1fr;
    max-width: 750px;
    /* margin: 0 1rem; */
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const GridItems = styled.div`
  padding: 3rem 0rem;
  height: 830px;
  border-radius: 35px;
  width: 360px;
  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
  }
`;
export const Con = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
  margin: 12px 0;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
`;
export const ImgCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1.5rem 3rem 1.5rem;
`;

export const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 1.5rem;
`;

export const Feature = styled.li`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.8px;
  color: #282d2b;
  list-style: none;
`;

export const OverviewCon = styled.div`
  margin: 0rem 4rem 2rem 4rem;
  @media (max-width: 1180px) {
    margin: 0rem 2rem 2rem 2rem;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #cbd1ce;
`;

export const Thead = styled.thead`
  border: 1px solid #cbd1ce;
  background-color: #eaf0ee;
`;

export const Tbody = styled.tbody`
  width: 100%;

  border: 1px solid #cbd1ce;
`;

export const Th = styled.th`
  border: 1px solid #cbd1ce;
  padding: 1rem;
  font-family: '"Kumbh Sans", sans-serif';
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #10281e;
`;
export const Row = styled.tr`
  border: 1px solid #cbd1ce;
`;

export const Column = styled.td`
  border: 1px solid #cbd1ce;
  padding: 1rem;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #10281e;
`;

export const OverviewMobileCon = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: block;
    margin: 0 2rem;
  }
  @media (max-width: 900px) {
    display: block;
    margin: 0 1rem;
  }
`;

export const Plan = styled.div`
  width: 100%;
  background-color: rgba(234, 240, 238, 1);
  border: 1px solid rgba(203, 209, 206, 1);
  margin-bottom: 16px;
  border-radius: 35px;
  padding: 1rem;
`;

export const PlanTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FeaturesMobile = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
`;
