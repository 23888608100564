import React from "react";
import { BigText, Container, Desc, SmallText, Wrapper } from "./Third_styles";

const Third = () => {
  return (
    <Wrapper>
      <Container>
        <SmallText>A LOOK AT SPOWSE</SmallText>
        <BigText>Ditch The Swipe Struggle And Slide Into Love</BigText>
        <Desc>
          Spowse is the app built to spark your love story. We go beyond the
          swipe! We combine your interests, culture, language and location to
          find compatible partners who share your world. Explore and connect
          with potential partners using our fun and intuitive sliding feature
          that makes finding love easy. Slide through profiles, delve deeper and
          spark connections with ease. With Spowse, express your interests
          clearly and find lasting love.
        </Desc>
      </Container>
    </Wrapper>
  );
};

export default Third;
