import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";


export const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght,YOPQ@100..900,40..300&display=swap');


@font-face {
  font-family: "geist-bold";
  src: url("/fonts/Geist-Bold.otf") format("opentype");
}

@font-face {
  font-family: "geist-medium";
  src: url("/fonts/Geist-Medium.otf") format("opentype");
}

@font-face {
  font-family: "geist-regular";
  src: url("/fonts/Geist-Regular.otf") format("opentype");
}

@font-face {
  font-family: "geist-light";
  src: url("/fonts/Geist-Light.otf") format("opentype");
}




* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "geist-light";
}

body {
  font-family: 'geist-medium';
  width: 100%;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb { 
  background-color: ${theme.colors.black};
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  display: none;
  background-color: rgba(0, 0, 0, 0.13);
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

/* For Microsoft Edge */
*::-ms-scrollbar {
  width: 8px;
}

*::-ms-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

*::-ms-scrollbar-track {
  background-color: #f0f0f0;
}
`;
