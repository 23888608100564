export const support = [
  {
    icon: "/assets/icons/help.svg",
    title: "Help & Support",
    desc: "We are here to help with questions about Spowse.",
    linkText: "Get support",
    url: "/contact-us/help-and-support",
  },
  {
    icon: "/assets/icons/partner.svg",
    title: "Partnerships",
    desc: "Interested in partnering with us? We’d love to hear from you.",
    linkText: "Get in touch",
    url: "/contact-us/submit-a-request",
  },
  {
    icon: "/assets/icons/success.svg",
    title: "Success Stories",
    desc: "Feel free to tell us how you met your sweetheart on Spowse.",
    linkText: "Submit your story",
    url: "/success-story",
  },
  {
    icon: "/assets/icons/faq.svg",
    title: "FAQs",
    desc: "Get answers to commonly asked questions about Spowse.",
    linkText: "Read now",
    url: "/faqs",
  },
];
