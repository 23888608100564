import React from "react";
import Hero from "./components/hero/Hero";
import Footer from "../footer/Footer";
import Support from "./components/support/Support";
import { Helmet } from "react-helmet-async";

const ContactUs = () => {
  return (
    <div>
      <Helmet>
        <title>Contact Us - Spowse</title>
        <meta
          name="description"
          content="Have questions or need support? Contact the Spowse team for assistance with your dating experience. We're here to help with any inquiries or feedback. Reach out to us for quick and friendly support, and let us guide you on your journey to finding meaningful connections."
        />
        <meta charSet="utf-8" />
        <link rel="canonical" href={`/contact-us`} />
      </Helmet>
      <Hero />
      <Support />
      <Footer />
    </div>
  );
};

export default ContactUs;
