import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  margin: 2rem 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.88)),
    url("/assets/images/safety_cta.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 70vh;

  @media (max-width: 800px) {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.65),
        rgba(0, 0, 0, 0.88)
      ),
      url("/assets/images/safety_cta_mobile.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    height: 100%;
    margin: 0;
  }
`;

export const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 8rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  @media (max-width: 800px) {
    padding: 8rem 1.5rem;
  }
`;
