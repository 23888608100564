import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const Wrapper = styled.section`
  width: 100%;
  margin: 8rem 0 5rem 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url("/assets/images/about-bg1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 70vh;

  @media (max-width: 800px) {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.65),
        rgba(0, 0, 0, 0.65)
      ),
      url("/assets/images/about-ish.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    height: 100%;
  }
`;

export const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 8rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  @media (max-width: 800px) {
    padding: 4rem 1.5rem;
  }
  `;

export const SmallText = styled.p`
  color: ${theme.colors.white};
  font-weight: 900;
  font-size: 16px;
  font-family: '"Kumbh Sans", sans-serif';
  letter-spacing: 0.8px;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const BigText = styled.h1`
  color: ${theme.colors.white};
  font-weight: 900;
  margin: 1rem 0;
  font-size: 50px;
  font-family: '"Kumbh Sans", sans-serif';
  letter-spacing: 0.8px;
  @media (max-width: 800px) {
    font-size: 28px;
    line-height: 35px;
    margin: 0.5rem 0 1rem;
  }
`;

export const Desc = styled.p`
  font-family: "geist-light";
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.8px;
  line-height: 22px;
  color: ${theme.colors.white};
  width: 1050px;
  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;
