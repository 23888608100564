import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 3rem 4rem;
  @media (max-width: 900px) {
    padding: 2rem 1.5rem;
  }
`;

export const Container = styled.div`
    margin: 50px 0;
`;

export const ContentFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 126px;
  align-items: center;
  margin-top: 60px;
  @media (max-width: 1200px) {
    gap: 50px;
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const LeftImgCon = styled.div`
  flex: 1;
  width: 100%;
`;

export const Right = styled.div`
  flex: 1;
`;

export const Left = styled.div``;

export const Down = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 126px;
  /* row-gap: 50px; */
  @media (max-width: 1200px) {
    column-gap: 50px;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    margin-top: 0;
  }
`;

export const FeatureTexts = styled.div`
  margin-bottom: 1.5rem;
`;
