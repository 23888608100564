// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hide{
  max-height: 0;
  overflow: hidden;
  /* transition: all 0.5s cubic-bezier(0, 1, 0, 1); */
}
  .show {
    height: auto;
    max-height: 999px;
    /* transition: all 0.5s cubic-bezier(1, 0, 1, 0); */
  }   `, "",{"version":3,"sources":["webpack://./src/presentations/product/product.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,mDAAmD;AACrD;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,mDAAmD;EACrD","sourcesContent":[".hide{\n  max-height: 0;\n  overflow: hidden;\n  /* transition: all 0.5s cubic-bezier(0, 1, 0, 1); */\n}\n  .show {\n    height: auto;\n    max-height: 999px;\n    /* transition: all 0.5s cubic-bezier(1, 0, 1, 0); */\n  }   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
