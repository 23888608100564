import ApiService from '../api';
import { ApiUrl } from '../api_url';

interface ContactUsData {
  firstName: string;
  lastName: string;
  email: string;
  subject?: string;
  howCanHelp: string;
  description: string;
}


class ContactService {
  private apiService: ApiService;   

  constructor() {
    this.apiService = new ApiService();
  }

  async submitContactUsForm(data: ContactUsData): Promise<any> {
    const response = await this.apiService.postData<ContactUsData, any>(ApiUrl.CONTACT_US, data);
    
    return {
      ...response,
      message: response.message || 'No message provided',
    };
  }
}

export default new ContactService();
