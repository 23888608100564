export const theme = {
  colors: {
    primary: "rgba(16, 40, 30, 1)",
    bgPrimary: "rgba(0, 64, 33, 1)",
    secondary: "rgba(9, 152, 56, 1)",
    black: "#131110",
    white: "#ffffff",
    blueGradient: "linear-gradient(97.05deg, #10281d 23%, #ffffff 31%)",
    orangeGradient: "linear-gradient(270deg, #ffb978 0%, #ff922d 100%)",
    lightGreen: "#7bf0a2",
    lightGreen1: "rgba(9, 152, 56, 1)",
    blue: "#4066ff",
    lightBlue: "rgba(239, 247, 244, 1)",
    shadow: "0px 23px 21px -8px rgba(136, 160, 255, 0.25)",
    offWhite: "rgba(242, 242, 242, 1)",
    borderColor: "rgba(203, 209, 206, 1)",
  },
};
