import styled from "styled-components";

export const DiscoverLoveWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 150px 4rem;

  @media (max-width: 800px) {
    padding: 3rem 1.5rem;
  }
`;

export const DiscoverLoveContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1500px;
  flex-direction: column;
  gap: 1rem;
`;

export const DiscoverLoveGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 30px;
  column-gap: 20px;
  margin-top: 3rem;
`;

export const DiscoverLoveGridChild = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  & > :nth-child(1) {
    margin-bottom: 10px;
  }
`;
