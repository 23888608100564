export const values = [
  {
    icon: "/assets/images/culture.svg",
    title: "Culture",
    desc: "We believe that shared cultural experiences create stronger bonds. We embrace diverse African traditions, languages, and customs, helping you find love that feels familiar and culturally inclined. ",
  },
  {
    icon: "/assets/images/genuine.svg",
    title: "Genuineness",
    desc: " We believe in real connections. Spowse encourages users to be their authentic selves. We encourage our users to feel comfortable sharing their true personalities, interests, and aspirations, leading to meaningful and lifelong relationships.",
  },
  {
    icon: "/assets/images/empathy.svg",
    title: "Empathy",
    desc: "We know that everyone's story is unique. We provide a space that promotes confidence without fear of judgement. Our community is built on kindness, where experiences and feelings are valued.",
  },
  {
    icon: "/assets/images/wellness.svg",
    title: "Wellness",
    desc: "We prioritise emotional well-being, and encourage healthy relationships with oneself and others. We support and provide a positive environment where our users can explore connections at their own pace, always putting their mental and emotional health first.",
  },
  {
    icon: "/assets/images/safety.svg",
    title: "Safety",
    desc: "We recognise the importance of trust in online connections. Spowse is built with robust security features to safeguard privacy and personal information while helping you find love that feels familiar.",
  },
];
