import React from "react";
import {
  BigText,
  Container,
  Desc,
  Left,
  Right,
  SmallText,
  Span,
  SpanBig,
  TextCon,
  Wrapper,
} from "./Cta_styles";

const Cta = () => {
  return (
    <Wrapper>
      <Container>
        <Left>
          <SmallText>CARE TO KNOW MORE?</SmallText>
          <TextCon>
            <BigText>
              With Spowse, you can feel at home and{" "}
              <SpanBig> connect to your roots</SpanBig> in the best way possible.
            </BigText>
          </TextCon>
        </Left>
        <Right>
          <Desc>
            Across the dating world, finding love and building lasting
            connections with someone who shares your heritage can be tough. At{" "}
            <Span>Spowse</Span>, we understand the importance of finding love
            that <Span>reflects your background</Span>, someone who gets your
            jokes and shares your interests.
          </Desc>
          <Desc>
            That is why we created Spowse, a safe space for African singles to
            connect, build meaningful relationships and find love that truly
            defines you.{" "}
          </Desc>
          <Desc>
            Let Spowse be that bridge to{" "}
            <Span>a love that feels like home</Span>. Connect with us today and
            start writing your own African love story.
          </Desc>
        </Right>
      </Container>
    </Wrapper>
  );
};

export default Cta;
